import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { useAuth } from '../../authContext';

const UserDashboard = () => {
  const { currentUser } = useAuth();
  const topbarHeight = 64; // Adjust this value to match the height of your topbar

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: `${topbarHeight}px` }}>
        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            User Dashboard
          </Typography>
          <Typography variant="h6" gutterBottom>
            Welcome to your personalized dashboard, {currentUser?.email}
          </Typography>
          {/* <Routes>
            <Route path="profile" element={<Profile />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="admin" element={<Admin />} />
            <Route path="admin/events" element={<Events />} />
            <Route path="admin/news" element={<News />} />
            <Route path="admin/quotes" element={<Quotes />} />
            <Route path="admin/calls" element={<Calls />} />
          </Routes> */}
        </Container>
      </Box>
    </Box>
  );
};

export default UserDashboard;