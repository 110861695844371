import { Alert, Button, Card, CardActions, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField, Typography } from '@mui/material';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { firestore } from '../../../firebase';

const TicketSystemPage = () => {
  const [tickets, setTickets] = useState([]);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [reply, setReply] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchTickets = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'tickets'));
      const ticketsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTickets(ticketsData);
    };

    fetchTickets();
  }, []);

  const handleReply = async () => {
    if (currentTicket) {
      try {
        await updateDoc(doc(firestore, 'tickets', currentTicket.id), {
          status: 'Replied',
          reply,
        });

        // Code to send an email to the user with the reply should be added here.

        setSnackbarMessage('Reply sent successfully');
        setOpenSnackbar(true);
        setOpenDialog(false);
        setReply('');
        setCurrentTicket(null);
      } catch (error) {
        setSnackbarMessage('Failed to send reply');
        setOpenSnackbar(true);
      }
    }
  };

  const handleOpenDialog = (ticket) => {
    setCurrentTicket(ticket);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setReply('');
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Tech Support Tickets</Typography>
      {tickets.map((ticket) => (
        <Card key={ticket.id} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6">Subject: {ticket.subject}</Typography>
            <Typography variant="body1">Message: {ticket.message}</Typography>
            <Typography variant="body2">Status: {ticket.status}</Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" onClick={() => handleOpenDialog(ticket)}>
              Reply
            </Button>
          </CardActions>
        </Card>
      ))}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Reply to Ticket</DialogTitle>
        <DialogContent>
          <TextField
            label="Reply"
            variant="outlined"
            multiline
            fullWidth
            rows={4}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleReply} color="primary">Send Reply</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TicketSystemPage;
