import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar, Box, Button, Card, CardContent, CardMedia, CircularProgress, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { collection, getDocs, getFirestore, limit, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useNavigate } from 'react-router-dom';
import { InstagramEmbed } from 'react-social-media-embed';
import { useConsent } from '../context/ConsentContext';

import homeImage5 from '../assets/Home/fifth.jpg';
import homeImage1 from '../assets/Home/first.jpg';
import homeImage4 from '../assets/Home/fourth.png';
import homeImage2 from '../assets/Home/second.jpg';
import homeImage3 from '../assets/Home/third.jpg';

import avatar1 from '../assets/Home/avatar1.jpg';
import avatar2 from '../assets/Home/avatar2.jpg';
import avatar3 from '../assets/Home/avatar3.jpg';
import avatar4 from '../assets/Home/avatar4.jpg';
import avatar5 from '../assets/Home/avatar5.jpg';
import avatar6 from '../assets/Home/avatar6.png';

// Partner Logos
import partnerLogo1 from '../assets/Home/Partner_EuropaClubs.jpg';
import partnerLogo2 from '../assets/Home/Partner_EuropeDirect.png';
import partnerLogo5 from '../assets/Home/Partner_Federal_Chancellery_Republic.png';
import partnerLogo3 from '../assets/Home/Partner_Federal_Ministry.png';
import partnerLogo4 from '../assets/Home/Partner_tpa.jpg';

const items = [
  {
    image: homeImage1,
    text: "Intercultural experiences",
  },
  {
    image: homeImage2,
    text: "Join Us in Our Events",
  },
  {
    image: homeImage3,
    text: "Become a Member",
  },
  {
    image: homeImage4,
    text: "Learn More About Us",
  },
  {
    image: homeImage5,
    text: "Join Our Community",
  },
];

const testimonials = [
  {
    name: "Roberta Metsola",
    title: "President of the European Parliament",
    text: "The European Youth Parliament is of remarkable value as a platform to foster political engagement among European youth.",
    avatar: avatar1,
    link: "@RobertaMetsola on Twitter",
  },
  {
    name: "Asdin El Habbassi, BA",
    title: "Member of the Austrian Parliament",
    text: "The participation of young people is like the sun for a tree, indispensable in a strong Europe. Be a part of EYP. Your future, your Europe!",
    avatar: avatar2,
    link: "Austrian Parliament",
  },
  {
    name: "Mag. Maria Pichlbauer",
    title: "Counsellor for EU and Internationalisation at the local school authority in Styria",
    text: "I consider the EYP an ideal instrument for enhancing pan-European political education that at the same time provides language training and the development of knowledge and skills. I advise all secondary and vocational schools to join the EYP programme and take part in their activities.",
    avatar: avatar3,
  },
  {
    name: "Jochen Zöschg",
    title: "Zurich Insurance Group",
    text: "Young, highly committed people in intercultural cooperation. That is politics, society and economy – lived in a refreshing way. I was impressed by the energy and the positive approach to quite complex topics. Thank you for the inspiring exchange!",
    avatar: avatar4,
  },
  {
    name: "Robert Hietz",
    title: "Student and member of EYP Austria",
    text: "EYP doesn’t only mean getting new friends wherever you go, but also constantly being challenged to become better in every way. Apart from that, it means trying to find out what being European really means.",
    avatar: avatar5,
  },
  {
    name: "Emily Usner",
    title: "President of EYP Austria 2022/23",
    text: "EYP is the perfect combination of fun and learning, challenge and safety, individual development and collaboration. A youth organisation for the future of Europe.",
    avatar: avatar6,
  },
];

const CustomCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '10px 20px',
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
  backgroundColor: '#FFA500', // Custom orange color
  color: 'white',
  '&:hover': {
    backgroundColor: '#FF8C00', // Darker orange on hover
  },
}));

const PartnerLogo = styled('img')(({ theme }) => ({
  width: '150px',
  height: 'auto',
  margin: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out, filter 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.2)',
    filter: 'brightness(1.3)',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  color: theme.palette.text.primary,
  textAlign: 'center',
  variant: 'h4',
  marginBottom: theme.spacing(4),
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '16px',
  color: theme.palette.text.primary,
}));

const CenteredText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '5rem', // Adjusted for larger text
  fontWeight: 900, // Bold weight for emphasis
  fontFamily: 'Raleway, sans-serif', // Use Raleway font
  color: 'white',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)', // Adds contrast to the text against the background
  textAlign: 'center',
  letterSpacing: '0.1rem',
  lineHeight: 1.2, // Adjust line-height for better spacing
  zIndex: 2,
  maxWidth: '90%', // Ensure the text doesn’t overflow the carousel width
  whiteSpace: 'pre-line', // Ensure the text can wrap
  transition: 'color 0.3s, text-shadow 0.3s',
  '&:hover': {
    color: '#FFD700', // Highlight yellow on hover
    textShadow: '4px 4px 8px rgba(255, 215, 0, 0.8)', // Increase shadow on hover
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black overlay
  zIndex: 1, // Behind the text but above the image
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffd700', // Gold color for a premium look
  color: '#000',
  padding: '10px 20px',
  fontSize: '1rem',
  fontWeight: 'bold',
  borderRadius: '25px',
  marginTop: theme.spacing(3),
  '&:hover': {
    backgroundColor: '#e6c200', // Slightly darker gold on hover
  },
}));

const HighlightedBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 215, 0, 0.1)', // Light gold background to highlight the section
  padding: theme.spacing(4),
  borderRadius: '15px',
}));

const HomePage = () => {
    const { consent, openConsentDialog } = useConsent();
    const [news, setNews] = useState([]);
    const [nextEvent, setNextEvent] = useState(null);
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchNews = async () => {
        const db = getFirestore();
        const newsQuery = query(collection(db, 'news'), orderBy('timestamp', 'desc'), limit(3));
        const newsSnapshot = await getDocs(newsQuery);
        const newsData = newsSnapshot.docs.map(doc => doc.data());
        setNews(newsData);
      };
  
      const fetchNextEvent = async () => {
        const db = getFirestore();
        const currentTimestamp = new Date();
        const eventQuery = query(
          collection(db, 'events'),
          where('startDate', '>', currentTimestamp),
          orderBy('startDate', 'asc'),
          limit(1)
        );
        const eventSnapshot = await getDocs(eventQuery);
        const eventData = eventSnapshot.docs.map(doc => doc.data())[0];
        setNextEvent(eventData);
      };
  
      fetchNews();
      fetchNextEvent();
    }, []);
  
    return (
      <div>
        <Carousel
          NextIcon={<ArrowForwardIosIcon />}
          PrevIcon={<ArrowBackIosIcon />}
          navButtonsAlwaysVisible
          navButtonsProps={{
            style: {
              backgroundColor: 'transparent',
              borderRadius: 0,
              margin: 0,
              padding: 0,
            }
          }}
          navButtonsWrapperProps={{
            style: {
              top: 'calc(50% - 20px)',
              height: '40px',
            }
          }}
          height={600}
        >
          {items.map((item, index) => (
            <Paper key={index} style={{ position: 'relative', height: '100%' }}>
              <img src={item.image} alt={item.text} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              <Overlay />
              <CenteredText>
                WELCOME TO EYP AUSTRIA
              </CenteredText>
            </Paper>
          ))}
        </Carousel>
  
        <Container maxWidth="lg" sx={{ mt: 8 }}>
          <SectionTitle variant="h2">Discover Our Programs</SectionTitle>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <CustomCard>
                <CardMedia component="img" height="200" image={homeImage1} alt="For Students" />
                <CardContent>
                  <CustomTypography variant="h5">For Students</CustomTypography>
                  <CustomButton variant="contained" color="primary" fullWidth onClick={() => navigate(`/about-us`)}>
                    Learn More
                  </CustomButton>
                </CardContent>
              </CustomCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomCard>
                <CardMedia component="img" height="200" image={homeImage2} alt="For Teachers" />
                <CardContent>
                  <CustomTypography variant="h5">For Teachers</CustomTypography>
                  <CustomButton variant="contained" color="primary" fullWidth onClick={() => navigate(`/about-us`)}>
                    Learn More
                  </CustomButton>
                </CardContent>
              </CustomCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomCard>
                <CardMedia component="img" height="200" image={homeImage3} alt="For Partners" />
                <CardContent>
                  <CustomTypography variant="h5">For Partners</CustomTypography>
                  <CustomButton variant="contained" color="primary" fullWidth onClick={() => navigate(`/partner`)}>
                    Learn More
                  </CustomButton>
                </CardContent>
              </CustomCard>
            </Grid>
          </Grid>
  
          <Divider sx={{ my: 8 }} />
  
          <SectionTitle variant="h2">Upcoming Events & Opportunities</SectionTitle>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <CustomCard sx={{ textAlign: 'center', p: 4 }} onClick={() => navigate(`/events`)} style={{ cursor: 'pointer' }}>
                <CustomTypography variant="h6">Next Event</CustomTypography>
                {nextEvent ? (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {nextEvent.title} <br />
                    {new Date(nextEvent.startDate.toDate()).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })} - {new Date(nextEvent.endDate.toDate()).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </Typography>
                ) : (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    No upcoming events found.
                  </Typography>
                )}
              </CustomCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomCard sx={{ textAlign: 'center', p: 4 }} onClick={() => navigate('/membership')} style={{ cursor: 'pointer' }}>
                <CustomTypography variant="h6">Sign up to the Newsletter</CustomTypography>
                <Box component="img" src={homeImage2} alt="Newsletter" sx={{ width: '100%', mt: 2, borderRadius: '16px' }} />
              </CustomCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomCard sx={{ textAlign: 'center', p: 4 }} onClick={() => navigate('/membership')} style={{ cursor: 'pointer' }}>
                <CustomTypography variant="h6">Join Us</CustomTypography>
                <Box component="img" src={homeImage1} alt="Join Us" sx={{ width: '100%', mt: 2, borderRadius: '16px' }} />
              </CustomCard>
            </Grid>
          </Grid>
          <Divider sx={{ my: 8 }} />
           {/* Partner Section */}
          <Container maxWidth="lg" sx={{ mt: 8 }}>
            <SectionTitle>Our Partners</SectionTitle>
            <HighlightedBox>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>
                  <PartnerLogo src={partnerLogo1} alt="Partner 1" />
                </Grid>
                <Grid item>
                  <PartnerLogo src={partnerLogo2} alt="Partner 2" />
                </Grid>
                <Grid item>
                  <PartnerLogo src={partnerLogo3} alt="Partner 3" />
                </Grid>
                <Grid item>
                  <PartnerLogo src={partnerLogo4} alt="Partner 4" />
                </Grid>
                <Grid item>
                  <PartnerLogo src={partnerLogo5} alt="Partner 5" />
                </Grid>
              </Grid>
              <Box textAlign="center">
                <StyledButton onClick={() => navigate('/partner')}>
                  View All Partners
                </StyledButton>
              </Box>
            </HighlightedBox>
          </Container> 
          <Divider sx={{ my: 8 }} />
  
          <Box sx={{ mt: 4, marginBottom: '2vw' }}>
            <Typography variant="h4" component="div" sx={{ mb: 2, textAlign: 'center' }}>
              Others on EYP
            </Typography>
            <Grid container spacing={2} sx={{ paddingBottom: '4rem' }}>
              {testimonials.map((testimonial, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box display="flex" alignItems="flex-start" flexGrow={1}>
                      <Avatar src={testimonial.avatar} sx={{ width: 70, height: 70, mr: 2 }} />
                      <Box>
                        <Typography variant="body1">{testimonial.text}</Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                          - {testimonial.name}, {testimonial.title}
                        </Typography>
                        {testimonial.link && (
                          <Typography variant="body2" color="primary">
                            {testimonial.link}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {index % 2 !== 0 && index < testimonials.length - 1 && (
                    <Grid item xs={12}>
                      <Box sx={{ textAlign: 'center', my: 2, display: 'flex', justifyContent: 'center' }}>
                        <Box
                          sx={{
                            width: '40%',
                            height: '1px',
                            backgroundColor: '#ccc',
                          }}
                        />
                        <Box sx={{ width: '6vw' }} /> {/* Adjust this width to create the gap in the middle */}
                        <Box
                          sx={{
                            width: '40%',
                            height: '1px',
                            backgroundColor: '#ccc',
                          }}
                        />
                      </Box>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Box>
  
          <Divider sx={{ my: 8 }} />
  
          <SectionTitle variant="h2">Latest News & Social Updates</SectionTitle>
          <Grid container spacing={6} sx={{ paddingBottom: '2rem' }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center' }}>
                <CustomTypography variant="h6">Latest News</CustomTypography>
                {news.map((item, index) => (
                  <CustomCard key={index} sx={{ mb: 4, p: 2 }}>
                    <Typography variant="h6">{item.title}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                      {new Date(item.timestamp.toDate()).toDateString()}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      {item.content}
                    </Typography>
                  </CustomCard>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', position: 'relative', width: '100%', maxWidth: 540, margin: 'auto' }}>
                <Typography variant="h6">Latest Instagram Post</Typography>
  
                {consent.thirdParty ? (
                  <InstagramEmbed
                    url="https://www.instagram.com/reel/C5VVwxxxSK8/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
                    width="100%"
                    maxWidth={540}
                    hideCaption={false}
                    containerTagName="div"
                    protocol=""
                    injectScript
                    onLoading={() => <CircularProgress />}
                    onSuccess={() => console.log('Loaded')}
                    onAfterRender={() => console.log('Rendered')}
                    onFailure={(error) => console.error('Error:', error)}
                  />
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      maxWidth: 540,
                      height: 400, // Set a height that mimics the height of the Instagram post
                      backgroundColor: '#f0f0f0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      borderRadius: 2,
                      border: '2px dashed #ccc',
                    }}
                  >
                    <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
                      Instagram content is disabled.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={openConsentDialog}
                      sx={{ textTransform: 'none' }}
                    >
                      Change Cookie Settings to View
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
};

export default HomePage;
