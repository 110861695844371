// src/pages/Impressum.js

import { Container, Typography } from '@mui/material';
import React from 'react';

const Impressum = () => (
  <Container sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
    <Typography variant="body1">
      Europäisches Jugendparlament Österreich
      Lassingleithnerplatz 2/3
      1020 Wien Austria

      ZVR: 760986885
      Email: info@eyp.at

      The European Youth Parliament Austria is an organisation of BEJ/JEF. BEJ/JEF is supported by the Bundesministerium für Arbeit, Familie und Jugend (Federal Ministry of Labour, Family and Youth) and the Bundesministerium für Bildung, Wissenschaft und Forschung (Federal Ministry of Education, Science and Research), as well as the city of Vienna (Education and Youth).
      All our activities are done within the EuropaClubs initiative (www.europaclubs.at).

      What is EYP

      The European Youth Parliament is carried by students in a voluntary capacity and has no political mandate. All activities in Austria are done within the EuropaClubs initiative (www.europaclubs.at).

      The European Youth Parliament Austria is a national committee (NC) of the European Youth Parliament on the international level. The Schwarzkopf Foundation is the international umbrella organisation of the European Youth Parliament.

      The organisation’s aim is to facilitate Austrian’s participation at the European Youth Parliament. Furthermore, the understanding of democracy, political participation and political education of young people is facilitated. The organisation is non-partisan, non-confessional and non-governmental.

      The Board and Advisory Board are listed in the Team section.

      Our statutes can be found here.
    </Typography>
  </Container>
);

export default Impressum;
