import { Box, Container } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const DashboardLayout = ({ children, topbarHeight }) => {
  const location = useLocation();
  const isDashboard = location.pathname.startsWith('/user-dashboard');

  return (
    <Box sx={{ display: 'flex' }}>
      {isDashboard && <Sidebar topbarHeight={topbarHeight} />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: isDashboard ? `${topbarHeight}px` : 0,
        }}
      >
        <Container sx={{ paddingTop: isDashboard ? '20px' : 0, paddingBottom: '20px' }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
