import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../authContext';
import ErrorPage from './ErrorPage'; // Update the path based on your project structure

const AdminRoute = ({ children, requiredPermission }) => {
  const { currentUser, userPermissions } = useAuth(); // Fetch userPermissions instead of roles
  console.log(userPermissions); // Useful for debugging

  // If no user is logged in, redirect to the sign-in page
  if (!currentUser) {
    return <Navigate to="/sign-in" />;
  }

  // If permissions are still being loaded, show a loading spinner
  if (userPermissions === null) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Check if the user has the required permission
  if (!userPermissions.includes(requiredPermission)) {
    return <ErrorPage message="You do not have the necessary permissions to access this page." />;
  }

  // If the user has the correct permission, render the protected content
  return children;
};

export default AdminRoute;
