import { Popper, Paper, ClickAwayListener, Button, Typography, Grow } from '@mui/material';
import React from 'react';

const availableRoles = ['Head-Organiser', 'Mentor', 'Editor', 'Head of Jury', 'President', 'Vice-President'];

function RoleSelector({ anchorEl, open, onClose, onSelectRole }) {
  return (
    <Popper open={open} anchorEl={anchorEl} transition>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <ClickAwayListener onClickAway={onClose}>
              <div>
                <Typography variant="subtitle1" gutterBottom>Select Role</Typography>
                {availableRoles.map((role) => (
                  <Button
                    key={role}
                    variant="outlined"
                    sx={{ mr:1, mb:1 }}
                    onClick={() => onSelectRole(role)}
                  >
                    {role}
                  </Button>
                ))}
                <Button variant="text" color="error" onClick={onClose}>Cancel</Button>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default RoleSelector;
