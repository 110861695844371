import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography
} from '@mui/material';

const CallDialog = ({ open, onClose, onSave, initialData, isEditing }) => {
  const [form, setForm] = useState({
    title: '',
    description: '',
    endDate: '',
    endTime: '',
    document: null,
    link: '',
  });

  useEffect(() => {
    if (initialData) {
      const end = initialData.endDate ? initialData.endDate.toDate() : null;
      let endDateStr = '';
      let endTimeStr = '';

      if (end) {
        endDateStr = end.toISOString().split('T')[0]; // YYYY-MM-DD
        // Extract HH:MM from the date's time
        const hours = String(end.getHours()).padStart(2, '0');
        const minutes = String(end.getMinutes()).padStart(2, '0');
        endTimeStr = `${hours}:${minutes}`;
      }

      setForm({
        title: initialData.title || '',
        description: initialData.description || '',
        endDate: endDateStr,
        endTime: endTimeStr,
        document: null, // For editing/duplication, user can re-upload if they want
        link: initialData.applyLink || ''
      });
    } else {
      setForm({
        title: '',
        description: '',
        endDate: '',
        endTime: '',
        document: null,
        link: '',
      });
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate form
    if (!form.title || !form.description || !form.endDate || !form.endTime) {
      return;
    }

    // Combine endDate and endTime into a single Date object
    const combinedDateTime = new Date(`${form.endDate}T${form.endTime}:00`);
    if (isNaN(combinedDateTime.getTime())) {
      // Invalid date/time combination
      return;
    }

    onSave({
      ...form,
      // Replace endDate string with the actual combined Date object
      endDate: combinedDateTime
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isEditing ? 'Edit/Duplicate Call' : 'Create Call'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Call Title"
            variant="outlined"
            fullWidth
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={form.description}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
            sx={{ mb: 2 }}
          />
          <Box display="flex" gap={2} sx={{ mb: 2 }}>
            <TextField
              label="End Date"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={form.endDate}
              onChange={(e) => setForm({ ...form, endDate: e.target.value })}
            />
            <TextField
              label="End Time"
              type="time"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={form.endTime}
              onChange={(e) => setForm({ ...form, endTime: e.target.value })}
            />
          </Box>
          <Box mb={2}>
            <Button variant="outlined" component="label" sx={{ mr: 2 }}>
              Upload Document
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                hidden
                onChange={(e) => setForm({ ...form, document: e.target.files[0] })}
              />
            </Button>
            {form.document && (
              <Typography variant="body1">{form.document.name}</Typography>
            )}
            <TextField
              label="Or Add Link"
              variant="outlined"
              fullWidth
              value={form.link}
              onChange={(e) => setForm({ ...form, link: e.target.value })}
              sx={{ mt: 2 }}
            />
          </Box>
          <DialogActions>
            <Button onClick={onClose} color="primary">Cancel</Button>
            <Button type="submit" color="primary">{isEditing ? 'Save Changes' : 'Create'}</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CallDialog;
