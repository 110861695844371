import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, TextField, Button } from '@mui/material';
import { useAuth } from '../../authContext';
import { getDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import ErrorPage from '../../components/ErrorPage';
import axios from 'axios';

const EditAboutUsPage = () => {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({
    sections: [],
    images: {},
  });

  const [originalContent, setOriginalContent] = useState({}); // For discarding changes

  // Check user permissions
  const checkAdminRole = async () => {
    if (currentUser) {
      try {
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userRoles = userDoc.data().roles || [];
          let hasAdminPermission = false;

          for (const roleId of userRoles) {
            const roleDoc = await getDoc(doc(firestore, 'roles', roleId));
            if (roleDoc.exists()) {
              const rolePermissions = roleDoc.data().permissions || [];
              if (rolePermissions.includes('manage_content') || rolePermissions.includes('admin')) {
                hasAdminPermission = true;
                break;
              }
            }
          }

          setIsAdmin(hasAdminPermission);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Error checking admin role:', error);
      }
    }
  };

  // Fetch content from backend
  const fetchContent = async () => {
    try {
      const response = await axios.get('/api/get-webtext', { params: { webtext_id: 1 } });
      const { webtext_org_text, webtext_trans_text } = response.data;

      setContent({
        sections: [
          { id: 'section1', title: 'What is EYP?', text: webtext_org_text, editable: false },
          { id: 'section2', title: 'EYP Sessions', text: webtext_trans_text, editable: false },
        ],
        images: {
          section1Image: '/assets/AboutUs/aboutus_whateyp.jpg',
          section2Image: '/assets/AboutUs/aboutus_teambuilding.jpg',
        },
      });

      setOriginalContent({
        sections: [
          { id: 'section1', title: 'What is EYP?', text: webtext_org_text, editable: false },
          { id: 'section2', title: 'EYP Sessions', text: webtext_trans_text, editable: false },
        ],
        images: {
          section1Image: '/assets/AboutUs/aboutus_whateyp.jpg',
          section2Image: '/assets/AboutUs/aboutus_teambuilding.jpg',
        },
      });
    } catch (error) {
      console.error('Error fetching page content:', error);
    }
  };

  // Combine permission check and fetch content in useEffect
  useEffect(() => {
    const initializePage = async () => {
      await checkAdminRole();
      await fetchContent();
      setLoading(false);
    };

    initializePage();
  }, [currentUser]);

  // Toggle edit mode for a section
  const toggleEdit = (sectionId) => {
    const updatedSections = content.sections.map((section) =>
      section.id === sectionId ? { ...section, editable: !section.editable } : section
    );
    setContent({ ...content, sections: updatedSections });
  };

  // Handle text changes in editable sections
  const handleTextChange = (sectionId, value) => {
    const updatedSections = content.sections.map((section) =>
      section.id === sectionId ? { ...section, text: value } : section
    );
    setContent({ ...content, sections: updatedSections });
  };

  // Save changes to backend
  const handleSave = async () => {
    try {
      const [section1, section2] = content.sections;
      await axios.post('/api/update-webtext', {
        webtext_id: 1,
        webtext_org_text: section1.text,
        webtext_trans_text: section2.text,
      });
      alert('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving content:', error);
    }
  };

  // Discard changes and revert to original content
  const handleDiscard = () => {
    setContent(originalContent);
  };

  if (loading) {
    return <Typography align="center">Loading...</Typography>;
  }

  if (!isAdmin) {
    return <ErrorPage message="You do not have permission to edit this page." />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, position: 'relative' }}>
      {/* Sticky Save and Discard Buttons */}
      <Box
        sx={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          display: 'flex',
          gap: '10px',
          zIndex: 1000,
        }}
      >
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save Changes
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleDiscard}>
          Discard Changes
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom align="center">
        Edit About Us Page
      </Typography>
      <Box>
        {content.sections.map((section) => (
          <Box key={section.id} sx={{ mt: 4, border: section.editable ? '1px dashed #FFA500' : 'none', p: 2 }}>
            <Typography variant="h5" gutterBottom>
              {section.title}
            </Typography>
            <Box sx={{ display: 'flex', gap: '20px', alignItems: 'flex-start' }}>
              {/* Editable Image */}
              <Box component="img" src={content.images[`${section.id}Image`]} alt={section.title} sx={{ width: '150px', borderRadius: '8px' }} />
              {/* Editable Text */}
              {section.editable ? (
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  value={section.text}
                  onChange={(e) => handleTextChange(section.id, e.target.value)}
                />
              ) : (
                <Typography>{section.text}</Typography>
              )}
            </Box>
            <Button
              variant="outlined"
              color="primary"k
              sx={{ mt: 2, mr: 2 }}
              onClick={() => toggleEdit(section.id)}
            >
              {section.editable ? 'Done' : 'Edit'}
            </Button>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default EditAboutUsPage;