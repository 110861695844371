import React from 'react';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';

const QuoteCard = ({ quote, actions }) => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar src={quote.imageUrl} sx={{ width: 60, height: 60, borderRadius: '50%', mr: 2 }} />
          <Box>
            <Typography variant="h6">{quote.author}</Typography>
            {quote.role && <Typography variant="body2">{quote.role}</Typography>}
          </Box>
        </Box>
        <Typography variant="body1" gutterBottom>"{quote.text}"</Typography>
        {quote.link && quote.linkText && (
          <Typography variant="body2" color="primary">
            <a href={quote.link} target="_blank" rel="noopener noreferrer">
              {quote.linkText}
            </a>
          </Typography>
        )}
      </CardContent>
      {actions && (
        <Box display="flex" justifyContent="flex-end" p={2}>
          {actions}
        </Box>
      )}
    </Card>
  );
};

export default QuoteCard;
