import { Box, Card, CardContent, Container, Grid, Typography, Breadcrumbs, Chip, Tooltip, IconButton, Divider } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';

const NewsPage = () => {
  const [newsArticles, setNewsArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;
  const [activeCategory, setActiveCategory] = useState('');


  useEffect(() => {
    const fetchNewsArticles = async () => {
      const db = getFirestore();
      const newsSnapshot = await getDocs(collection(db, 'news'));
      const newsData = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNewsArticles(newsData);
      setFilteredArticles(newsData); // Initial load shows all articles

    };

    fetchNewsArticles();
  }, []);

  const handleCategoryClick = (category) => {
    const newFilteredArticles = category ? 
      newsArticles.filter(article => article.category === category || !article.category) :
      newsArticles;
    setFilteredArticles(newFilteredArticles);
    setCurrentPage(1); // Reset to first page on filter change
    setActiveCategory(category); // Set the active category
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Assuming categories are extracted from news articles
  const categories = [...new Set(newsArticles.map(article => article.category).filter(Boolean))]; // Filter out undefined categories

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Header Section */}
      <Box textAlign="center" py={5}>
        <Typography variant="h3" gutterBottom sx={{ color: '#003366', fontWeight: 'bold' }}>
          Latest News
        </Typography>
        <Divider variant="middle" sx={{ my: 3, borderColor: '#003366', width: '50%', mx: 'auto' }} />
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Stay updated with the latest news and articles.
        </Typography>
      </Box>

      {/* Categories Section */}
      <Box sx={{ mb: 4 }}>
      <Typography variant="h6" sx={{ color: '#003366', fontWeight: 'bold', display: 'inline-block', mr: 1 }}>
          Categories
        </Typography>
        <Tooltip title="Click on a category to filter news articles by topic." arrow>
          <IconButton size="small" ><InfoIcon fontSize="small" /></IconButton>
        </Tooltip>
        <Breadcrumbs separator="|" sx={{ mt: 1 }}>
          <Chip 
            label="# All" 
            onClick={() => handleCategoryClick('')} 
            sx={{ cursor: 'pointer', fontWeight: 'bold', backgroundColor: activeCategory === '' ? '#FFA500' : 'transparent' }} 
          />
          {categories.map((category, index) => (
            <Chip
              key={index}
              label={`# ${category}`}
              onClick={() => handleCategoryClick(category)}
              sx={{ 
                cursor: 'pointer', 
                '&:hover': { backgroundColor: activeCategory === category ? '#FFA500' : '#eee' }, 
                backgroundColor: activeCategory === category ? '#FFA500' : 'transparent' // Active state
              }}
            />
          ))}
        </Breadcrumbs>
      </Box>

      {/* Main Content */}
      <Grid container spacing={4} sx={{ mt: 3 }}>
        {currentArticles.map((article) => (
          <Grid item xs={12} sm={6} md={4} key={article.id}>
            <Card
              sx={{
                boxShadow: 2,
                borderRadius: 2,
                overflow: 'hidden',
                transition: 'transform 0.3s ease-in-out',
                backgroundColor: '#ffffff',
                '&:hover': {
                  transform: 'scale(1.03)',
                },
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ color: '#003366', fontWeight: 'bold' }}>
                  {article.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  {article.content.length > 80
                    ? `${article.content.substring(0, 80)}...`
                    : article.content}
                </Typography>
                  {/* Breadcrumb for Category */}
                {article.category && (
                  <Breadcrumbs separator="|" sx={{ mb: 0 }}>
                    <Chip 
                      label={article.category} 
                      sx={{ 
                        
                      }} 
                    />
                  </Breadcrumbs>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Pagination */}
      <Box mt={4} display="flex" justifyContent="center">
        <Pagination
          count={Math.ceil(filteredArticles.length / articlesPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Container>
  );
};

export default NewsPage;
