import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../authContext';
import { getDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure you import your Firestore instance
import ErrorPage from '../../components/ErrorPage';

const PageSelector = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  const pages = [
    { label: 'About Us', icon: <PersonIcon />, path: '/user-dashboard/admin/about-us', permission: 'edit_about_us' },
    { label: 'Events', icon: <EventIcon />, path: '/user-dashboard/admin/events', permission: 'manage_events' },
    { label: 'News', icon: <NewspaperIcon />, path: '/user-dashboard/admin/news', permission: 'manage_news' },
    { label: 'Quotes', icon: <FormatQuoteIcon />, path: '/user-dashboard/admin/quotes', permission: 'manage_quotes' },
  ];

  useEffect(() => {
    const checkAdminRole = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userRoles = userDoc.data().roles || [];
          let hasAdminPermission = false;
          for (const roleId of userRoles) {
            const roleDoc = await getDoc(doc(firestore, 'roles', roleId));
            if (roleDoc.exists()) {
              const rolePermissions = roleDoc.data().permissions || [];
              if (rolePermissions.includes('admin') || rolePermissions.some(permission => pages.some(page => page.permission === permission))) {
                hasAdminPermission = true;
                break;
              }
            }
          }
          setIsAdmin(hasAdminPermission);
        } else {
          setIsAdmin(false);
        }
      }
      setLoading(false);
    };

    checkAdminRole();
  }, [currentUser, pages]);

  if (loading) {
    return <Typography align="center">Loading...</Typography>;
  }

  if (!isAdmin) {
    return <ErrorPage message="You do not have permission to edit pages." />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Select a Page to Edit
      </Typography>
      <Grid container spacing={4}>
        {pages.map((page, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {page.icon}
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {page.label}
                </Typography>
              </CardContent>
              <Button 
                variant="contained" 
                color="primary" 
                fullWidth 
                sx={{ mt: 2 }} 
                onClick={() => navigate(page.path)}
              >
                Edit {page.label}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PageSelector;
