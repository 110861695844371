import { Container, Typography } from '@mui/material';
import React from 'react';

const PrivacyPolicy = () => (
  <Container sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
    <Typography variant="body1">
      <strong>Data protection information according to article 13 GDPR</strong>
      <br />
      <a href="https://www.jusline.at/gesetz/dsgvo/paragraf/13" target="_blank" rel="noopener noreferrer">https://www.jusline.at/gesetz/dsgvo/paragraf/13</a>
      <br /><br />
      <strong>Responsible Entity</strong>
      <br />
      The association “European Youth Parliament Austria” (Europäisches Jugendparlament Österreich; in short: EYP Austria), Lassingleithnerplatz 2/3, 1020 Vienna, ZVR 760986885 is responsible for compliance with the General Data Protection Regulation (GDPR).
      <br /><br />
      <strong>Contact</strong>
      <br />
      If you have any questions regarding data protection, you can contact EYP Austria at any time at the following email address: info@eyp.at.
      <br /><br />
      <strong>Purpose of Data Collection</strong>
      <br />
      The following data categories in particular are subject to processing:
      <ul>
        <li>Master data: Gender, first name, last name, date of birth, street, door number, postal code, city, country, nationality, educational institution, telephone and email address, and other contact information necessary for electronic communication, bank details, socio-demographic data, information from online interaction (cookies).</li>
        <li>Event-related data: Information collected via event registration forms, such as dietary preferences, medical conditions, and other details necessary for event organization.</li>
      </ul>
      The personal data provided by the member, participant, or user when registering with EYP Austria is used for the purpose of contacting the person in accordance with the statutes (e.g., invitation to general meetings) or to enable the person to participate in events of the association (legal basis Art 6 para 1 lit b DSGVO).
      <br /><br />
      <strong>Event Registration via Google Forms</strong>
      <br />
      When you register for an event organized by EYP Austria through a Google Form, you consent to the processing of the personal data you provide in the form. This data may include, but is not limited to, your name, contact information, dietary preferences, medical conditions, and any other information necessary for the event’s organization and your participation.
      <br /><br />
      The data collected via Google Forms is used solely for the purpose of organizing and executing the event. In some cases, it may be necessary to share this data with our partners or third parties to comply with legal requirements, such as insurance or health and safety regulations. By submitting your data, you consent to this processing and sharing of your information.
      <br /><br />
      <strong>Legal Obligations</strong>
      <br />
      Legal obligations may also require the processing of personal data (legal basis Art 6 para 1 lit c DSGVO). If processing of personal data goes beyond the aforementioned reasons, EYP Austria obtains the consent of the data subjects (legal basis Art 6 Abs 1 lit a DSGVO). In the case of consent, the data is processed exclusively for the stated purpose. Consent given can be revoked at any time. The revocation of consent does not affect the lawfulness of the processing of personal data carried out until the revocation. The revocation can be issued to info@eyp.at.
      <br /><br />
      <strong>Storage Period</strong>
      <br />
      The personal data of the member, participant, or user collected in the course of registration with EYP Austria will be stored as long as this is necessary for the event organization and post-processing or until the termination of membership, unless storage of the personal data is required beyond this due to legal retention periods.
      <br /><br />
      <strong>Recipients</strong>
      <br />
      The personal data of the member, participant, or user collected in the context of the registration with EYP Austria will not be passed on to third parties by EYP Austria without cause. Depending on the purpose of the processing, data may be passed on to public bodies, partner associations, or third parties if this is necessary to fulfill this purpose (e.g., event reporting, insurance, or legal compliance). The EYP Austria carefully chooses its partners to ensure that personal data are processed confidentially and carefully. A transfer of personal data to third parties takes place in the case of participation in an event of a partner association organized by EYP Austria. In this case, the following personal data will be transmitted: Gender, first name, last name, date of birth, street, door number, postal code, city, country, nationality, telephone and email address, and other information necessary for addressing resulting from modern communication techniques.
      <br /><br />
      <strong>Instagram Embeds</strong>
      <br />
      Our website includes Instagram embeds. When you view an embedded Instagram post, Instagram may collect data about your interaction with the embed, such as your IP address and browser information. This data may be stored and processed by Instagram in the United States or other countries outside of the European Union. For more information on how Instagram processes your data, please refer to their privacy policy available at <a href="https://privacycenter.instagram.com/policy" target="_blank" rel="noopener noreferrer">Instagram's Privacy Policy</a>.
      <br /><br />
      <strong>Hosting with Easyname</strong>
      <br />
      We host our website with Easyname. Easyname is compliant with GDPR and has implemented appropriate technical and organizational measures to protect personal data. For more information, please see their privacy policy available at <a href="https://www.easyname.com/en/legal/privacy" target="_blank" rel="noopener noreferrer">Easyname's Privacy Policy</a>.
      <br /><br />
      <strong>User Rights</strong>
      <br />
      The member, participant, or user has the right to information about the processed personal data, their rectification, erasure, and restriction of processing by EYP Austria, unless legal or contractual rights and/or obligations of the EYP Austria oppose the request of the member or participant. A given consent can be revoked at any time by sending an email to info@eyp.at. The consequences of connected withdrawals from the association or event cancellations under civil (and association) law remain thereby unaffected. The member, participant, or user also has a right of complaint to the supervisory authority: Austrian Data Protection Authority, Wickenburggasse 8-10, 1080 Vienna, email: dsb@dsb.gv.at.
      <br /><br />
      <strong>Automated Decision-Making</strong>
      <br />
      No automated decision-making or profiling is used.
      <br />
      This privacy policy is subject to change without notice and was last updated on 15.08.2024.
    </Typography>
  </Container>
);

export default PrivacyPolicy;
