import React, { useState, useRef } from 'react';
import { Avatar, Box, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import AvatarEditor from 'react-avatar-editor';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const EditQuote = ({ currentQuote, open, onClose }) => {
  const [author, setAuthor] = useState(currentQuote?.author || '');
  const [text, setText] = useState(currentQuote?.text || '');
  const [role, setRole] = useState(currentQuote?.role || '');
  const [link, setLink] = useState(currentQuote?.link || '');
  const [linkText, setLinkText] = useState(currentQuote?.linkText || '');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(currentQuote?.imageUrl || '');
  const [error, setError] = useState('');
  const editorRef = useRef(null);
  const [zoom, setZoom] = useState(1);
  const db = getFirestore();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
    } else {
      alert('Please upload a valid image.');
    }
  };

  

  const handleImageUpload = async () => {
    if (!image) return imageUrl; 
    const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
    const storage = getStorage();
    const storageRef = ref(storage, `quote-images/${Date.now()}_${image.name}`);
    const blob = await (await fetch(canvas)).blob();
    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const handleSubmit = async () => {
    if (!author || !text || (!image && !imageUrl)) {
      setError('Author, Quote, and Image are required.');
      return;
    }

    try {
      const uploadedImageUrl = await handleImageUpload();
      const quoteRef = doc(db, 'quotes', currentQuote.id); 
      await updateDoc(quoteRef, {
        author,
        text,
        role,
        imageUrl: uploadedImageUrl,
        link,
        linkText,
      });
      setError('');
      onClose();
    } catch (err) {
      console.error('Error updating quote:', err);
      setError('An error occurred while updating the quote.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Quote</DialogTitle>
      <DialogContent>
        <TextField
          label="Author *"
          variant="outlined"
          fullWidth
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Role"
          variant="outlined"
          fullWidth
          value={role}
          onChange={(e) => setRole(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Quote *"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={text}
          onChange={(e) => setText(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Link URL (optional)"
          variant="outlined"
          fullWidth
          value={link}
          onChange={(e) => setLink(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Link Text (optional)"
          variant="outlined"
          fullWidth
          value={linkText}
          onChange={(e) => setLinkText(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Box mb={2}>
          <Button variant="outlined" component="label">
            Upload Image *
            <input type="file" accept="image/*" hidden onChange={handleFileChange} />
          </Button>
        </Box>
        {image && (
          <Box mb={2} textAlign="center">
            <AvatarEditor
              ref={editorRef}
              image={image}
              width={150}
              height={150}
              border={50}
              borderRadius={75}
              scale={zoom}
              rotate={0}
            />
            <Box mt={2} display="flex" justifyContent="center">
              <IconButton onClick={() => setZoom(zoom + 0.1)}>
                <ZoomIn />
              </IconButton>
              <IconButton onClick={() => setZoom(zoom - 0.1)}>
                <ZoomOut />
              </IconButton>
            </Box>
          </Box>
        )}
        {imageUrl && !image && (
          <Box mb={2} textAlign="center">
            <Avatar src={imageUrl} sx={{ width: 120, height: 120, mx: 'auto', borderRadius: '50%' }} />
          </Box>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Update Quote
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditQuote;
