import { Button, Card, CardContent, Container, Typography, Grid, Box, Divider, Avatar } from '@mui/material';
import { collection, getDocs, query, Timestamp, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { firestore } from '../firebase';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AnnouncementIcon from '@mui/icons-material/Announcement';

const EventsPage = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [openCalls, setOpenCalls] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventsAndCalls = async () => {
      try {
        const eventsCollection = collection(firestore, 'events');
        const callsCollection = collection(firestore, 'calls');
        const now = Timestamp.fromDate(new Date());
        const upcomingQuery = query(eventsCollection, where('endDate', '>=', now));
        const pastQuery = query(eventsCollection, where('endDate', '<', now));
        const callsQuery = query(callsCollection, where('endDate', '>=', now));

        const [upcomingSnapshot, pastSnapshot, callsSnapshot] = await Promise.all([
          getDocs(upcomingQuery),
          getDocs(pastQuery),
          getDocs(callsQuery),
        ]);

        setUpcomingEvents(upcomingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setPastEvents(pastSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setOpenCalls(callsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (err) {
        console.error('Error fetching events and calls:', err);
        setError(err.message);
      }
    };

    fetchEventsAndCalls();
  }, []);

  const renderEventCard = (event) => (
    <Card key={event.id} sx={{ mb: 4, display: 'flex', alignItems: 'center', ':hover': { boxShadow: 6 } }} elevation={3}>
      <CardContent sx={{ flex: 1 }}>
        <Box display="flex" alignItems="center" mb={1}>
          <EventAvailableIcon color="primary" sx={{ mr: 1 }} />
          <Typography
            variant="h6"
            component={RouterLink}
            to={`/events/${event.id}`}
            sx={{ textDecoration: 'none', color: 'primary.main' }}
          >
            {event.title}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary" mb={1}>
          {new Date(event.startDate.toDate()).toLocaleDateString()} - {new Date(event.endDate.toDate()).toLocaleDateString()}
        </Typography>
        <Button component={RouterLink} to={`/events/${event.id}`} variant="contained" sx={{ mt: 2 }}>
          Learn more
        </Button>
      </CardContent>
      {event.logoUrl && (
        <Avatar
          src={event.logoUrl}
          alt={`${event.title} logo`}
          sx={{ width: 100, height: 100, mr: 2 }}
          variant="rounded"
        />
      )}
    </Card>
  );

  const renderCallCard = (call) => (
    <Card key={call.id} sx={{ mb: 4, ':hover': { boxShadow: 6 } }} elevation={3}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={1}>
          <AnnouncementIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ color: 'primary.main' }}>
            {call.title}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary" paragraph>
          {call.description}
        </Typography>
        <Typography variant="body2" color="text.primary" paragraph>
          <strong>Apply before:</strong> {new Date(call.endDate.toDate()).toLocaleDateString()}
        </Typography>
        <Button component="a" href={call.applyLink} target="_blank" rel="noopener noreferrer" variant="contained" color="primary" sx={{ mt: 2 }}>
          Apply Now
        </Button>
      </CardContent>
    </Card>
  );

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        {/* Main Section for Events */}
        <Grid item xs={12} md={8} sx={{paddingRight: 2}}>
          <Box display="flex" alignItems="center" mb={2}>
            <EventAvailableIcon color="primary" sx={{ mr: 1, fontSize: 36 }} />
            <Typography variant="h4" sx={{ color: 'primary.dark', fontWeight: 'bold' }}>Upcoming Events</Typography>
          </Box>
          {upcomingEvents.length > 0 ? upcomingEvents.map(renderEventCard) : (
            <Typography variant="body1">There are no upcoming events.</Typography>
          )}

          <Divider sx={{ my: 4 }} />

          <Box display="flex" alignItems="center" mb={2}>
            <EventAvailableIcon color="secondary" sx={{ mr: 1, fontSize: 36 }} />
            <Typography variant="h4" sx={{ color: 'secondary.dark', fontWeight: 'bold' }}>Past Events</Typography>
          </Box>
          {pastEvents.length > 0 ? pastEvents.map(renderEventCard) : (
            <Typography variant="body1">There are no past events.</Typography>
          )}
        </Grid>

        {/* Sidebar for Open Calls */}
        <Divider orientation="vertical" flexItem sx={{ mr: -1 }} />
        <Grid item xs={12} md={4}>
          <Box sx={{ position: 'sticky', top: 20, ml: 2 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <AnnouncementIcon color="primary" sx={{ mr: 1, fontSize: 32 }} />
              <Typography variant="h5" sx={{ color: 'primary.dark', fontWeight: 'bold' }}>Open Calls</Typography>
            </Box>
            {openCalls.length > 0 ? openCalls.map(renderCallCard) : (
              <Typography variant="body1">There are no open calls at the moment.</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventsPage;
