import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { firestore } from '../firebase';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchResultsPage = () => {
  const query = useQuery();
  const searchQuery = query.get('query');
  const [results, setResults] = useState([]);

  useEffect(() => {
    const search = async () => {
      const resultsArray = [];

      // Define collections to search in
      const collections = ['news', 'events'];

      for (const collectionName of collections) {
        const colRef = collection(firestore, collectionName);
        const snapshot = await getDocs(colRef);
        snapshot.forEach((doc) => {
          const data = doc.data();
          const content = data.content || '';
          const title = data.title || '';

          if (content.includes(searchQuery) || title.includes(searchQuery)) {
            resultsArray.push({ ...data, id: doc.id, collection: collectionName });
          }
        });
      }
      setResults(resultsArray);
    };

    search();
  }, [searchQuery]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Search Results for "{searchQuery}"
      </Typography>
      <Typography variant="h6" gutterBottom>
        {results.length} result{results.length !== 1 ? 's' : ''} found
      </Typography>
      <Grid container spacing={4}>
        {
          results.map((result) => (
            <Grid item xs={12} md={6} key={result.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {result.collection} - {result.title}
                  </Typography>
                  {result.content && (
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      {result.content.substring(0, 100)}...
                    </Typography>
                  )}
                  <Button
                    component={RouterLink}
                    to={`/${result.collection}/${result.id}`}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))
        }
      </Grid>
    </Container>
  );
};

export default SearchResultsPage;
