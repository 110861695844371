import React, { useEffect, useState } from 'react';
import {
  Container, Typography, Button, Box, TextField, FormControl, InputLabel,
  Select, MenuItem, Snackbar, Alert, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Chip, IconButton, Stepper, Step, StepLabel
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { collection, addDoc, getDocs, getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DynamicTable from '../DynamicTable';
import RoleSelector from './RoleSelector';

const steps = [
  'Event Basics',
  'Media & Description',
  'Contacts (with roles) & Partners',
  'Schedules',
  'Review & Submit'
];

const CreateEvent = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [sessionType, setSessionType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  const [headerImage, setHeaderImage] = useState(null);
  const [headerImageUrl, setHeaderImageUrl] = useState('');
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [existingContacts, setExistingContacts] = useState([]);
  const [existingPartners, setExistingPartners] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contactRoles, setContactRoles] = useState({});
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [schedules, setSchedules] = useState([]);

  const [currentStep, setCurrentStep] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);

  // Role editing
  const [roleAnchorEl, setRoleAnchorEl] = useState(null);
  const [editingRoleItem, setEditingRoleItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const contactsSnap = await getDocs(collection(db, 'contacts'));
      const contactsData = contactsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setExistingContacts(contactsData);

      const partnersSnap = await getDocs(collection(db, 'partners'));
      const partnersData = partnersSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setExistingPartners(partnersData);
    };
    fetchData();
  }, []);

  const handleFileChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
    } else {
      setErrorMessage('Invalid file type. Please select an image.');
      setOpenSnackbar(true);
    }
  };

  const handleImageUpload = async (image, folder, setUrlState) => {
    if (!image) return '';
    const storage = getStorage();
    const uniqueName = `${Date.now()}-${image.name}`;
    const storageRef = ref(storage, `${folder}/${uniqueName}`);
    await uploadBytes(storageRef, image);
    const url = await getDownloadURL(storageRef);
    setUrlState(url);
    return url;
  };

  const validateStep = () => {
    switch (currentStep) {
      case 0:
        if (!title || !sessionType || !startDate || !endDate || new Date(startDate) >= new Date(endDate)) {
          setErrorMessage('Please fill all required fields and ensure end date is after start date.');
          setOpenSnackbar(true);
          return false;
        }
        return true;
      case 1:
        if (!description) {
          setErrorMessage('Description is required.');
          setOpenSnackbar(true);
          return false;
        }
        return true;
      case 2:
        for (let cid of selectedContacts) {
          if (!contactRoles[cid]) {
            setErrorMessage('All selected contacts must have a role assigned.');
            setOpenSnackbar(true);
            return false;
          }
        }
        return true;
      case 3:
        return true;
      case 4:
        return true;
      default:
        return false;
    }
  };

  const handleNext = () => {
    if (!validateStep()) return;
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
    } else {
      // Submit event
      handleSubmit();
    }
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleDiscardChanges = () => {
    setDiscardDialogOpen(true);
  };

  const confirmDiscardChanges = () => {
    setDiscardDialogOpen(false);
    navigate('/events');
  };

  const cancelDiscardChanges = () => {
    setDiscardDialogOpen(false);
  };

  const handleRoleEditClick = (id, anchor) => {
    setEditingRoleItem({ type: 'contact', id });
    setRoleAnchorEl(anchor);
  };

  const handleRoleSelect = (role) => {
    if (!editingRoleItem) return;
    setContactRoles({ ...contactRoles, [editingRoleItem.id]: role });
    handleRoleClose();
  };

  const handleRoleClose = () => {
    setEditingRoleItem(null);
    setRoleAnchorEl(null);
  };

  const handleSubmit = async () => {
    try {
      const db = getFirestore();
      const updatedHeaderImageUrl = headerImage ? await handleImageUpload(headerImage, 'header-images', setHeaderImageUrl) : headerImageUrl;
      const updatedLogoUrl = logo ? await handleImageUpload(logo, 'event-logos', setLogoUrl) : logoUrl;

      await addDoc(collection(db, 'events'), {
        title,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        headerImageUrl: updatedHeaderImageUrl || '',
        logoUrl: updatedLogoUrl || '',
        description,
        contacts: selectedContacts.map(cid => {
          const c = existingContacts.find(ct => ct.id === cid);
          return { ...c, role: contactRoles[cid] };
        }),
        partners: selectedPartners.map(pid => existingPartners.find(p => p.id === pid)),
        sessionType,
        schedules: schedules.length > 0 ? JSON.stringify(schedules) : '[]',
      });

      navigate('/events');
    } catch (error) {
      console.error('Error creating event:', error);
      setErrorMessage('Error creating event. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <TextField
              label="Event Title *"
              fullWidth
              sx={{ mb: 2 }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Box display="flex" gap={2} mb={2}>
              <TextField
                label="Start Date *"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                label="End Date *"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Box>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>Session Type *</InputLabel>
              <Select
                value={sessionType}
                onChange={(e) => setSessionType(e.target.value)}
                label="Session Type"
              >
                <MenuItem value="regional">Regional</MenuItem>
                <MenuItem value="national">National</MenuItem>
                <MenuItem value="mini">Mini Session</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="subtitle1" gutterBottom>Header Image (Optional)</Typography>
            <Button variant="outlined" component="label" sx={{ mb: 2 }}>
              Upload Image
              <input type="file" accept="image/*" hidden onChange={(e) => handleFileChange(e, setHeaderImage)} />
            </Button>
            {(headerImageUrl || headerImage) && (
              <Box mb={2}>
                <img
                  src={headerImage ? URL.createObjectURL(headerImage) : headerImageUrl}
                  alt="Header Preview"
                  style={{ maxWidth: '100%', maxHeight: '150px' }}
                />
              </Box>
            )}

            <Typography variant="subtitle1" gutterBottom>Event Logo (Optional)</Typography>
            <Button variant="outlined" component="label" sx={{ mb: 2 }}>
              Upload Logo
              <input type="file" accept="image/*" hidden onChange={(e) => handleFileChange(e, setLogo)} />
            </Button>
            {(logo || logoUrl) && (
              <Box mb={2}>
                <img
                  src={logo ? URL.createObjectURL(logo) : logoUrl}
                  alt="Logo Preview"
                  style={{ maxWidth: '100%', maxHeight: '150px' }}
                />
              </Box>
            )}

            <Typography variant="subtitle1" gutterBottom>Description *</Typography>
            <ReactQuill
              value={description}
              onChange={setDescription}
              style={{ minHeight: '200px' }}
            />
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="subtitle1" gutterBottom>Select Contacts (with roles) (Optional)</Typography>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>Select Contacts</InputLabel>
              <Select
                multiple
                value={selectedContacts}
                onChange={(e) => setSelectedContacts(e.target.value)}
                label="Select Contacts"
              >
                {existingContacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mb={2}>
              {selectedContacts.map((id) => {
                const contact = existingContacts.find(c => c.id === id);
                if (!contact) return null;
                const role = contactRoles[id];
                return (
                  <Chip
                    key={id}
                    label={`${contact.name}${role ? ` (${role})` : ''}`}
                    onDelete={() => {
                      const newContacts = selectedContacts.filter(cid => cid !== id);
                      setSelectedContacts(newContacts);
                      const newRoles = { ...contactRoles };
                      delete newRoles[id];
                      setContactRoles(newRoles);
                    }}
                    sx={{ mr:1, mb:1 }}
                    icon={
                      <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleRoleEditClick(id, e.currentTarget) }}>
                        <Edit fontSize="inherit" />
                      </IconButton>
                    }
                  />
                );
              })}
            </Box>

            <Typography variant="subtitle1" gutterBottom>Select Partners (Optional)</Typography>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>Select Partners</InputLabel>
              <Select
                multiple
                value={selectedPartners}
                onChange={(e) => setSelectedPartners(e.target.value)}
                label="Select Partners"
              >
                {existingPartners.map((partner) => (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mb={2}>
              {selectedPartners.map(pid => {
                const partner = existingPartners.find(p => p.id === pid);
                if (!partner) return null;
                return (
                  <Chip
                    key={pid}
                    label={partner.name}
                    onDelete={() => setSelectedPartners(selectedPartners.filter(id => id !== pid))}
                    sx={{ mr:1, mb:1 }}
                  />
                );
              })}
            </Box>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Schedules (Optional)</Typography>
            <DynamicTable
              tables={schedules}
              onSave={(updatedSchedules) => setSchedules(updatedSchedules)}
            />
          </Box>
        );
      case 4:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Review & Submit</Typography>
            <Typography><strong>Title:</strong> {title}</Typography>
            <Typography><strong>Starts:</strong> {startDate}</Typography>
            <Typography><strong>Ends:</strong> {endDate}</Typography>
            <Typography><strong>Session Type:</strong> {sessionType}</Typography>
            <Typography><strong>Description:</strong> <span dangerouslySetInnerHTML={{ __html: description }} /></Typography>
            <Typography><strong>Contacts:</strong> {selectedContacts.length}</Typography>
            <Typography><strong>Partners:</strong> {selectedPartners.length}</Typography>
            <Typography><strong>Schedules:</strong> {schedules.length}</Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Create Event</Typography>

      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <Dialog open={discardDialogOpen} onClose={cancelDiscardChanges}>
        <DialogTitle>Discard Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to discard all changes? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDiscardChanges}>Cancel</Button>
          <Button onClick={confirmDiscardChanges} color="secondary">Discard</Button>
        </DialogActions>
      </Dialog>

      <RoleSelector
        anchorEl={roleAnchorEl}
        open={Boolean(roleAnchorEl)}
        onClose={handleRoleClose}
        onSelectRole={handleRoleSelect}
      />

      <Stepper activeStep={currentStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}><StepLabel>{label}</StepLabel></Step>
        ))}
      </Stepper>

      {renderStepContent(currentStep)}

      <Box mt={4} display="flex" justifyContent="space-between">
        <Button disabled={currentStep === 0} onClick={handleBack}>Back</Button>
        <Box>
          {currentStep === steps.length - 1 ? (
            <>
              <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mr: 2 }}>
                Submit
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleDiscardChanges}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button variant="text" color="error" onClick={handleDiscardChanges} sx={{ mr: 2 }}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default CreateEvent;
