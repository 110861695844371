import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import FacebookIcon from '@mui/icons-material/Facebook';
import ForumIcon from '@mui/icons-material/Forum';
import GroupIcon from '@mui/icons-material/Group';
import PublicIcon from '@mui/icons-material/Public';
import SchoolIcon from '@mui/icons-material/School';
import SupportIcon from '@mui/icons-material/Support';
import { Alert, Avatar, Box, Button, Container, Divider, Grid, MenuItem, Paper, Snackbar, TextField, Typography } from '@mui/material';
import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BackgroundImage from '../assets/Home/first.jpg'; // Importing the image

const benefitsBottom = [
  { title: 'Check for open calls', description: 'See the latest open calls and participate in various events and activities organized by EYP Austria.', link: '/events' },
  { title: 'Keep up with EYP Austria and our events by following us on Instagram', description: 'Follow us on Facebook to stay updated with the latest news, events, and opportunities.', link: '/' },
  { title: 'Join our Facebook group “Members of EYP Austria”', description: 'Connect with other members of EYP Austria and engage in discussions and activities through our Facebook group.', link: '#' },
  { title: 'Check your emails for the Member’s Edition of EYP Austria’s Newsletter', description: 'Receive our exclusive newsletter to get the latest updates, news, and insights directly to your inbox.', link: '#' },
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const benefits = [
  { icon: <PublicIcon />, title: 'Delegate abroad', description: 'Represent EYP Austria as a Delegate at sessions abroad' },
  { icon: <EmailIcon />, title: 'Newsletter', description: 'Receive EYP Austria’s International Newsletter with the latest EYP news from the international community' },
  { icon: <EventIcon />, title: 'Official in Austria', description: 'Participate as an Official (Chairperson, Journalist, …) at sessions of EYP Austria' },
  { icon: <FacebookIcon />, title: 'Facebook group', description: 'Join the Facebook group “Members of EYP Austria” to benefit from our network of friends' },
  { icon: <GroupIcon />, title: 'Teams', description: 'Join one of our Teams to actively shape EYP Austria' },
  { icon: <SchoolIcon />, title: 'Skills', description: 'Develop your skills at our training events' },
  { icon: <ForumIcon />, title: 'Discord server', description: 'Join the EYP Austria Discord server to connect with other members and find out about our activities' },
  { icon: <SupportIcon />, title: 'Support', description: 'Help us cover our administrative costs' },
  { icon: <BusinessCenterIcon />, title: 'Board', description: 'Elect the next Board of EYP Austria at our annual General Assembly' },
];

const BecomeMemberPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    email: '',
    phone: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    comments: '',
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [message, setMessage] = useState('');
  const [successNotification, setSuccessNotification] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    const serviceID = 'service_pldnu2b';
    const templateID = 'template_o5zbbu9';
    const userID = 'Vv5gb9elfimoVviUI';

    const templateParams = {
      ...formData,
      'g-recaptcha-response': captchaValue
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setMessage('Your registration has been submitted successfully.');
        setFormData({
          firstName: '',
          lastName: '',
          gender: '',
          dateOfBirth: '',
          email: '',
          phone: '',
          comments: '',
        });
        setSuccessNotification(true);
      }, (error) => {
        console.error('FAILED...', error);
        setMessage('There was an error submitting your registration. Please try again.');
      });
  };

  const handleCloseSnackbar = () => {
    setSuccessNotification(false);
  };

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '2rem' }}>
      <Snackbar
        open={successNotification}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Box textAlign="center" py={3} sx={{ paddingBottom: '2rem' }}>
        <Typography variant="h3" gutterBottom>
          National Newsletter Registration
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <img src={BackgroundImage} alt="Newsletter Background" style={{ width: '100%', borderRadius: '8px' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box id="mc_embed_signup">
              <form action="https://eyp.us5.list-manage.com/subscribe/post?u=f1d20796a28c9cda34dcb27a8&amp;id=3aa5e1128c&amp;f_id=00df25eaf0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <TextField
                  label="Email Address"
                  name="EMAIL"
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="First Name"
                  name="FNAME"
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Last Name"
                  name="LNAME"
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                />
                <div id="mce-responses" className="clear foot">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                <Button variant="contained" color="primary" type="submit" sx={{ width: '200px', mt: 2 }}>
                  Subscribe
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Typography
        variant="body1"
        sx={{
          fontStyle: 'italic',
          padding: '12px',
          borderLeft: '4px solid #d0d0d0',
          backgroundColor: '#f9f9f9',
          margin: '20px 0'
        }}
      >
        As every association, EYP Austria is defined by its members. Everyone participating in our activity automatically becomes an ordinary member of EYP Austria.
      </Typography>
      <Box textAlign="center" py={5} sx={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/eyp-at.appspot.com/o/header-images%2F1718237809529-3bysoxy32w.jpg?alt=media&token=82e27143-0bf1-43c5-9c1a-352de9ac494f)', backgroundSize: 'cover', borderRadius: '8px', color: '#fff', padding: '5vw', marginTop: '3vw' }}>
        <Typography variant="h3" gutterBottom>
          Become a Member!
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
          <Typography variant='h5' sx={{ width: '70%', fontWeight: '300' }} >
             Sign up and become a member now
          </Typography>
        </Box>
        
      </Box>
      

      <Box mt={5}>
        <Typography variant="h5" gutterBottom>
          As official member, you can:
        </Typography>
        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper elevation={3} sx={{ p: 2, textAlign: 'center', height: '100%', borderRadius: '8px' }}>
                <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56, mx: 'auto', mb: 2 }}>
                  {benefit.icon}
                </Avatar>
                <Typography variant="h6" gutterBottom>
                  {benefit.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {benefit.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={5} >
        <Typography variant="h4" textAlign="center"gutterBottom>
          How to join EYP Austria
        </Typography>
        <Divider sx={{ mb: 2, margin: '1vw' }} />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56, mb: 2 }}>1</Avatar>
              <Typography variant="h6" gutterBottom>
                Register
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
              <TextField label="First Name" variant="outlined" fullWidth required sx={{ mb: 2 }} name="firstName" value={formData.firstName} onChange={handleInputChange} />
              <TextField label="Last Name" variant="outlined" fullWidth required sx={{ mb: 2 }} name="lastName" value={formData.lastName} onChange={handleInputChange} />
              <TextField label="Gender" variant="outlined" fullWidth required select sx={{ mb: 2 }} name="gender" value={formData.gender} onChange={handleInputChange}>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
              <TextField label="Date of Birth" variant="outlined" fullWidth required sx={{ mb: 2 }} type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleInputChange} InputLabelProps={{ shrink: true }} />
              <TextField label="E-Mail" variant="outlined" fullWidth required sx={{ mb: 2 }} name="email" value={formData.email} onChange={handleInputChange} />
              <TextField label="Phone Number with country code (+43...)" variant="outlined" fullWidth required sx={{ mb: 2 }} name="phone" value={formData.phone} onChange={handleInputChange} />
              <TextField label="Comments" variant="outlined" fullWidth multiline rows={4} sx={{ mb: 2 }} name="comments" value={formData.comments} onChange={handleInputChange} />
              <ReCAPTCHA 
                sitekey="6LcH5fcpAAAAAPuqGbytv_XfYXxutSogGDFivudQ" 
                onChange={(value) => setCaptchaValue(value)} 
              />
              <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2, width: '200px' }}>
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" mt={4}>
          <Grid item xs={12} sm={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56, mb: 2 }}>2</Avatar>
              <Typography variant="h6" gutterBottom>
                Transfer membership fee
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1" paragraph>
              Account holder: Europäisches Jugendparlament Österreich
              <br />
              IBAN: AT341400004910800510
              <br />
              BIC: BAWAATWW
              <br />
              Reference: “MB2024/25 Surname, Firstname”
              <br />
              Amount: € 30,-
            </Typography>
            <Typography variant="body1">
              Your extraordinary membership is valid for one year from the day your membership fee arrives at our account.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" mt={4}>
          <Grid item xs={12} sm={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56, mb: 2 }}>3</Avatar>
              <Typography variant="h6" gutterBottom>
                Profit
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1" paragraph>
              After you’ve completed Step 1 and 2, wait until your payment is registered in our database. When you receive a confirmation email, your extraordinary membership is valid!
            </Typography>
            <Typography variant="body1" paragraph>
              You can start enjoying the benefits:
            </Typography>
            <Slider {...sliderSettings}>
              {benefitsBottom.map((benefit, index) => (
                <Box key={index} sx={{ padding: 2 }}>
                  <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="h6" gutterBottom>{benefit.title}</Typography>
                    <Typography variant="body1" gutterBottom>{benefit.description}</Typography>
                    <Typography variant="body1"><a href={benefit.link}>Learn more</a></Typography>
                  </Paper>
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>
      
      {/* <Box display="flex" alignItems="center" mt={2}>
        <Avatar src="path/to/natalie_avatar.jpg" sx={{ width: 56, height: 56, mr: 2 }} />
        <Box>
          <Typography variant="body1"><strong>Natalie Mullaeva</strong></Typography>
          <Typography variant="body2" color="textSecondary">Board Member for Finances</Typography>
          <Typography variant="body2" color="textSecondary">+43 650 7767060</Typography>
          <Typography variant="body2" color="textSecondary">natalie.mullaeva@eyp.at</Typography>
        </Box>
      </Box> */}
    </Container>
  );
};

export default BecomeMemberPage;
