import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { useConsent } from '../context/ConsentContext'; // Import the consent context

const Footer = () => {
  const { openConsentDialog } = useConsent(); // Get the function to open the consent dialog

  return (
    <footer>
      <Box py={3} bgcolor="lightgrey" textAlign="center">
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} European Youth Parliament Austria. All rights reserved.
        </Typography>
        <Typography variant="body2">
          <Link href="/impressum">Impressum</Link> | <Link href="/privacy">Data Privacy Policy</Link> | 
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Prevent navigation
              openConsentDialog(); // Open the consent dialog
            }}
          >
            Edit Cookie Consent
          </Link>
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
