import { Email, Phone } from '@mui/icons-material';
import { Avatar, Box, CardMedia, Container, Divider, Grid, Link, Paper, Typography } from '@mui/material';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import GroupImage from '../assets/Contact/contact_group.jpg';

const MeetTheTeamPage = () => {
  const [boardMembers, setBoardMembers] = useState([]);
  const [advisoryBoardMembers, setAdvisoryBoardMembers] = useState([]);
  const [welfareMembers, setWelfareMembers] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const db = getFirestore();
      const contactsSnapshot = await getDocs(collection(db, 'contacts'));
      const contactsData = contactsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const boardData = contactsData.filter(contact => contact.isBoardMember);
      const advisoryBoardData = contactsData.filter(contact => contact.isAdvisoryBoard);
      const welfareData = contactsData.filter(contact => contact.isSafePerson);

      setBoardMembers(sortMembersByRole(boardData));
      setAdvisoryBoardMembers(advisoryBoardData);
      setWelfareMembers(welfareData);
    };

    fetchTeamMembers();
  }, []);

  const sortMembersByRole = (members) => {
    const priorityRoles = ['President', 'Vice-President'];
    return members.sort((a, b) => {
      const aIndex = priorityRoles.findIndex(role => a.role.startsWith(role));
      const bIndex = priorityRoles.findIndex(role => b.role.startsWith(role));
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  };

  const renderContactCard = (member) => (
    <Paper
      elevation={6}
      sx={{
        textAlign: 'center',
        padding: 3,
        borderRadius: 3,
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}
    >
      <Avatar src={member.imageUrl} sx={{ width: 120, height: 120, mx: 'auto', mb: 2 }} />
      <Typography variant="h5" gutterBottom>
        {member.name}
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        {member.role}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      {member.phone && member.phone.trim() !== '' && (
        <Link href={`tel:${member.phone}`} color="inherit" sx={{ display: 'block', mb: 1 }}>
          <Phone sx={{ verticalAlign: 'middle', mr: 1 }} />
          {member.phone}
        </Link>
      )}
        <Link href={`mailto:${member.email}`} color="inherit">
          <Email sx={{ verticalAlign: 'middle', mr: 1 }} />
          {member.email}
        </Link>
      </Box>
    </Paper>
  );

  return (
    <Container maxWidth="lg">
      <Box textAlign="center" mb={5}>
        <CardMedia
          component="img"
          image={GroupImage}
          alt="Group Picture"
          sx={{
            width: '100%',
            maxWidth: 2000,
            margin: '0 auto',
          }}
        />
      </Box>
      <Typography variant="h10" gutterBottom>
        The European Youth Parliament Austria is a national committee (NC) of the European Youth Parliament
        on the international level. The Schwarzkopf Foundation is the international umbrella organisation of the European Youth Parliament.
      </Typography>
      <Box mb={5} sx={{ paddingTop: '4vw' }}>
        <Box textAlign="center" py={5}>
          <Typography variant="h3" gutterBottom>
            Meet the Team
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            Our dedicated team members who make everything possible.
          </Typography>
        </Box>
        <Typography variant="h4" gutterBottom>
          Board
        </Typography>
        <Grid container spacing={4}>
          {boardMembers.map((member) => (
            <Grid item xs={12} sm={6} md={4} key={member.id}>
              {renderContactCard(member)}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" gutterBottom>
          Advisory Board
        </Typography>
        <Grid container spacing={4}>
          {advisoryBoardMembers.map((member) => (
            <Grid item xs={12} sm={6} md={4} key={member.id}>
              {renderContactCard(member)}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" gutterBottom>
          Welfare
        </Typography>
        <Grid container spacing={4}>
          {welfareMembers.map((member) => (
            <Grid item xs={12} sm={6} md={4} key={member.id}>
              {renderContactCard(member)}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default MeetTheTeamPage;