import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../assets/General/errorGhost.svg';

const ErrorPage = ({ message }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{
        marginLeft: '-10vw',
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      alignItems: 'center',
      justifyContent: 'center',
      height: '50vh',
      textAlign: 'center',
    }}>
      <img src={NotFoundImage} alt="Not Found" style={{ maxWidth: '30%', height: 'auto', marginBottom: { xs: '20px', sm: 0 }, marginRight: { sm: '20px' } }} />
      <Box sx={{marginLeft: '10vw'}}>
        <Typography variant="h3" component="h1" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="h6" component="p" gutterBottom>
          {message}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<HomeIcon />}
          onClick={() => navigate('/')}
          sx={{ marginTop: 2 }}
        >
          Go to Homepage
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorPage;
