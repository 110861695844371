import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import CreateRoleForm from '../../../components/roles/CreateRoles';
import EditRoleForm from '../../../components/roles/EditRoles';
import { useAuth } from '../../../authContext';  // Assuming you have an auth context for managing users

const RoleManagement = () => {
  const { currentUser, userPermissions } = useAuth();  // Fetch permissions from useAuth context
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [refreshData, setRefreshData] = useState(false);  // To trigger data refresh

  const db = getFirestore();

  useEffect(() => {
    const fetchRolesAndUsers = async () => {
      const rolesSnapshot = await getDocs(collection(db, 'roles'));
      const usersSnapshot = await getDocs(collection(db, 'users'));
      
      setRoles(rolesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setUsers(usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchRolesAndUsers();
  }, [refreshData, db]); // Trigger refresh on changes

  console.log('Current user:', currentUser);
  console.log('Roles:', roles);

  // Check if the user has permission to manage roles
  const hasManageRolesPermission = userPermissions?.includes('manage_roles');

  // Conditional rendering based on user roles or permissions
  if (!hasManageRolesPermission) {
    return <Typography>You do not have permission to view this page.</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Role and User Management</Typography>
      
      {/* Create Role Section */}
      <CreateRoleForm onRoleCreated={() => setRefreshData(prev => !prev)} />

      {/* Edit User Roles Section */}
      <Typography variant="h5" sx={{ mt: 4 }}>Assign Roles to Users</Typography>
      <Grid container spacing={4}>
        {users.length > 0 ? (
          users.map(user => (
            <Grid item xs={12} sm={6} md={4} key={user.id}>
              <EditRoleForm 
                user={user} 
                roles={roles} 
                onRolesUpdated={() => setRefreshData(prev => !prev)} 
              />
            </Grid>
          ))
        ) : (
          <Typography variant="body2">No users found.</Typography>
        )}
      </Grid>
    </Container>
  );
};

export default RoleManagement;
