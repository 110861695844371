import React, { createContext, useContext, useEffect, useState } from 'react';

const ConsentContext = createContext();

export const ConsentProvider = ({ children }) => {
  const [consent, setConsent] = useState(() => {
    const savedConsent = localStorage.getItem('consent');
    return savedConsent ? JSON.parse(savedConsent) : { functional: true, analytics: false, thirdParty: false };
  });
  const [consentDialogOpen, setConsentDialogOpen] = useState(!localStorage.getItem('consent'));

  const handleConsentChange = (newConsent) => {
    const updatedConsent = { ...newConsent, functional: true }; // Ensure functional cookies are always enabled

    // If third-party consent is revoked, prevent loading third-party content
    

    setConsent(updatedConsent);
    localStorage.setItem('consent', JSON.stringify(updatedConsent));
    setConsentDialogOpen(false); // Close the dialog after consent is given
    if (consent.thirdParty && !updatedConsent.thirdParty) {
      // You can't delete third-party cookies directly, but you can prevent loading their content
      alert('Third-party content will no longer be loaded. Please clear your cookies manually if necessary.');
    }
  };

  const openConsentDialog = () => setConsentDialogOpen(true);

  const closeConsentDialog = () => setConsentDialogOpen(false);

  useEffect(() => {
    if (!localStorage.getItem('consent')) {
      openConsentDialog();
    }
  }, []);

  return (
    <ConsentContext.Provider value={{ consent, handleConsentChange, openConsentDialog, closeConsentDialog, consentDialogOpen }}>
      {children}
    </ConsentContext.Provider>
  );
};

export const useConsent = () => {
  return useContext(ConsentContext);
};
