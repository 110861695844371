import { Avatar, Box, Card, CardContent, CardMedia, Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Link as MuiLink, Divider } from '@mui/material';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const EventDetail = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      const db = getFirestore();
      const docRef = doc(db, 'events', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const eventData = docSnap.data();
        // Deserialize schedules if present
        if (eventData.schedules) {
          eventData.schedules = JSON.parse(eventData.schedules);
        }
        setEvent(eventData);
      } else {
        console.log('No such document!');
      }
    };

    fetchEvent();
  }, [id]);

  if (!event) return <Typography sx={{ mt: 4, textAlign: 'center' }}>Loading...</Typography>;

  const sessionTypeDisclaimer = {
    regional: (
      <>
        This is a Regional Session. Selected participants may be invited to future events. Learn more about these sessions and the European Youth Parliament at our <MuiLink component={Link} to="/about-us">About us</MuiLink> page.
      </>
    ),
    national: (
      <>
        This is a National Selection Conference. Selected participants may represent at higher-level sessions. Find out more on our <MuiLink component={Link} to="/about-us">About us</MuiLink> page.
      </>
    ),
    mini: (
      <>
        This is a Mini Session, a one-day event to spark engagement. Learn more about these events at our <MuiLink component={Link} to="/about-us">About us</MuiLink> page.
      </>
    ),
    other: ""
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  return (
    <Container sx={{ mb: 6 }}>
      {/* Header Image & Logo */}
      <Card sx={{ boxShadow: 0 }}>
        <CardMedia
          component="img"
          height="300"
          image={event.headerImageUrl}
          alt={event.title}
        />
        {event.logoUrl && (
          <Box
            component="img"
            src={event.logoUrl}
            alt={event.title}
            sx={{
              position: 'relative',
              top: '-100px',
              margin: '0 auto',
              display: 'block',
              height: '200px',
              objectFit: 'contain',
              zIndex: 1
            }}
          />
        )}
      </Card>

      {/* Title, Dates, and Description */}
      <Box textAlign="center" mt={-8}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb:1 }}>{event.title}</Typography>
        <Typography variant="h6">{formatDate(event.startDate)} - {formatDate(event.endDate)}</Typography>

        {event.sessionType && sessionTypeDisclaimer[event.sessionType] && (
          <Paper elevation={0} sx={{ p: 2, mt: 3, backgroundColor: '#f9f9f9', borderRadius: 2, display:'inline-block', textAlign:'left' }}>
            <Typography variant="body1" color="textSecondary">{sessionTypeDisclaimer[event.sessionType]}</Typography>
          </Paper>
        )}

        {event.description && (
          <Box mt={4} mb={6} textAlign="left" maxWidth="md" mx="auto">
            <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{ __html: event.description }} />
          </Box>
        )}
      </Box>

   {/* Partners (if any) - Make this section stand out */}
{event.partners && event.partners.length > 0 && (
  <Box 
    sx={{ 
      py: 6, 
      mb: 8, 
      backgroundColor: '#f0f8ff', 
      borderRadius: 3, 
      textAlign: 'center' 
    }}
  >
    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
      Supported By
    </Typography>
    <Grid 
      container 
      spacing={4} 
      justifyContent="center" // Ensures items are centered
      alignItems="center" // Vertically aligns items
    >
      {event.partners.map((partner, index) => (
        <Grid 
          item 
          xs={12} 
          sm={6} 
          md={event.partners.length === 1 ? 12 : 4} // Adjust width for a single partner
          key={index}
        >
          <Box sx={{ p: 2 }}>
            <Box
              component="img"
              src={partner.logoUrl}
              alt={partner.name}
              sx={{
                maxWidth: '100%',
                maxHeight: '150px',
                objectFit: 'contain',
                mb: 2,
                transition: 'transform 0.2s',
                '&:hover': { transform: 'scale(1.05)' }
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
              {partner.name}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
)}


      {/* People Involved (if any) */}
      {event.contacts && event.contacts.length > 0 && (
        <Box mb={8}>
          <Box textAlign="center" mb={4}>
            <Typography variant="h4" sx={{ fontWeight:'bold' }}>Our Team</Typography>
          </Box>
          <Grid container spacing={4}>
            {event.contacts.map((contact, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={2} sx={{ p:4, borderRadius:3, textAlign:'center' }}>
                  <Avatar 
                    src={contact.imageUrl}
                    sx={{ width: 100, height: 100, mx:'auto', mb:2 }}
                    alt={contact.name}
                  />
                  <Typography variant="h6" sx={{ fontWeight:'bold', mb:1 }}>{contact.name}</Typography>
                  {contact.role && <Typography variant="subtitle1" color="primary" sx={{ fontWeight:'medium', mb:1 }}>{contact.role}</Typography>}
                  {contact.email && <Typography variant="body2" color="textSecondary" sx={{ mb:0.5 }}>{contact.email}</Typography>}
                  {contact.phone && <Typography variant="body2" color="textSecondary">{contact.phone}</Typography>}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Schedules (if any) */}
      {event.schedules && event.schedules.length > 0 && (
        <Box mb={6}>
          {event.schedules.map((schedule, index) => (
            <Box key={index} mb={4}>
              {schedule.scheduleName && (
                <Box mb={2}>
                  <Typography variant="h5" sx={{ fontWeight:'medium', color:'text.primary' }}>
                    {schedule.scheduleName}
                  </Typography>
                  <Divider sx={{ mb:2 }} />
                </Box>
              )}
              <Paper elevation={0} sx={{ border:'1px solid #eee', borderRadius:2, overflowX:'auto' }}>
                <Table sx={{ minWidth: 600 }}>
                  <TableHead>
                    <TableRow>
                      {schedule.columns.map((column, colIndex) => (
                        <TableCell key={colIndex} sx={{ backgroundColor: '#f7f7f7', fontWeight:'medium' }}>
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {schedule.rows.map((row, rowIndex) => (
                      <TableRow key={rowIndex} hover>
                        {schedule.columns.map((column, colIndex) => (
                          <TableCell key={colIndex} sx={{ borderBottom:'1px solid #f0f0f0' }}>
                            {row[column] || '-'}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Box>
          ))}
        </Box>
      )}

    </Container>
  );
};

export default EventDetail;
