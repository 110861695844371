import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { useAuth } from '../../authContext';  // Assuming useAuth provides permissions

const CreateRoleForm = ({ onRoleCreated }) => {
  const [newRoleName, setNewRoleName] = useState('');
  const [newPermissions, setNewPermissions] = useState([]);
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);

  const { userPermissions } = useAuth();  // Access permissions
  const db = getFirestore();

  const permissions = [
    { name: 'manage_news', description: 'Allows managing news items' },
    { name: 'manage_events', description: 'Allows managing events' },
    { name: 'manage_calls', description: 'Allows managing calls' },
    { name: 'manage_quotes', description: 'Allows managing quotes' },
    { name: 'manage_roles', description: 'Allows managing user roles' },
  ];

  const handlePermissionChange = (permission) => {
    setNewPermissions(prev =>
      prev.includes(permission)
        ? prev.filter(p => p !== permission)
        : [...prev, permission]
    );
  };

  const handleNewRoleSubmit = async () => {
    if (newRoleName && newPermissions.length && userPermissions.includes('manage_roles')) {
      try {
        await addDoc(collection(db, 'roles'), {
          name: newRoleName,
          permissions: newPermissions,
        });
        setNewRoleName('');
        setNewPermissions([]);
        setRoleDialogOpen(false);
        onRoleCreated();  // Trigger refresh in parent component
      } catch (error) {
        console.error("Error creating role:", error);
        // Handle error display
      }
    } else {
      console.error("Missing permissions to create a role.");
      // Handle permission error
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 4 }}
        onClick={() => setRoleDialogOpen(true)}
      >
        Create New Role
      </Button>

      <Dialog open={roleDialogOpen} onClose={() => setRoleDialogOpen(false)}>
        <DialogTitle>Create New Role</DialogTitle>
        <DialogContent>
          <TextField
            label="Role Name"
            variant="outlined"
            fullWidth
            value={newRoleName}
            onChange={(e) => setNewRoleName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Typography variant="subtitle1" gutterBottom>Select Permissions:</Typography>
          {permissions.map((permission, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={newPermissions.includes(permission.name)}
                  onChange={() => handlePermissionChange(permission.name)}
                />
              }
              label={permission.description}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRoleDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleNewRoleSubmit} color="primary">Create Role</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateRoleForm;
