import React, { useState } from 'react';
import { Card, CardContent, Typography, Checkbox, FormControlLabel, Button } from '@mui/material';
import { getFirestore, updateDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../authContext';  // Assuming useAuth provides permissions

const EditRoleForm = ({ user, roles, onRolesUpdated }) => {
  const [userRoles, setUserRoles] = useState(user.roles || []);
  const { userPermissions } = useAuth();  // Access permissions
  const db = getFirestore();

  const handleRoleChange = (roleId) => {
    setUserRoles(prev =>
      prev.includes(roleId)
        ? prev.filter(id => id !== roleId)
        : [...prev, roleId]
    );
  };

  const handleRoleAssignment = async () => {
    if (userPermissions.includes('manage_roles')) {
      try {
        const userRef = doc(db, 'users', user.id);
        await updateDoc(userRef, {
          roles: userRoles,
        });
        onRolesUpdated();  // Trigger refresh in parent component
      } catch (error) {
        console.error("Error assigning roles:", error);
        // Handle error display
      }
    } else {
      console.error("Missing permissions to assign roles.");
      // Handle permission error
    }
  };

  return (
    <Card sx={{ cursor: 'pointer' }}>
      <CardContent>
        <Typography variant="h6">{user.email}</Typography>
        <Typography variant="body2" color="textSecondary">Assign Roles:</Typography>
        {roles.map((role) => (
          <FormControlLabel
            key={role.id}
            control={
              <Checkbox
                checked={userRoles.includes(role.id)}
                onChange={() => handleRoleChange(role.id)}
              />
            }
            label={role.name}
          />
        ))}
        <Button 
          variant="contained" 
          color="primary" 
          sx={{ mt: 2 }} 
          onClick={handleRoleAssignment}
        >
          Assign Roles
        </Button>
      </CardContent>
    </Card>
  );
};

export default EditRoleForm;
