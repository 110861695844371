import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import committeeWorkImage from '../assets/AboutUs/aboutus_committeework.jpg';
import culturalProgrammeImage from '../assets/AboutUs/aboutus_culturalProgramme.jpg';
import generalAssemblyImage from '../assets/AboutUs/aboutus_ga.jpg';
import teamImage from '../assets/AboutUs/aboutus_team.jpg';
import teambuildingImage from '../assets/AboutUs/aboutus_teambuilding.jpg';
import whatIsEYPImage from '../assets/AboutUs/aboutus_whateyp.jpg';

const AboutUsPage = () => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <Box>
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            What is EYP?
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={whatIsEYPImage}
                alt="EYP Introduction"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h8" color="textSecondary" align="left" paragraph>
                The European Youth Parliament (EYP) is a unique educational programme which brings together young people from all over Europe to discuss current topics in a parliamentary setting. Its mission is to support the development of young people into politically aware and responsible citizens by involving them in European political thinking and promoting intercultural understanding.
              </Typography>
              <Typography variant="h8" color="textSecondary" align="left" paragraph>
                The EYP is a non-partisan and independent programme; its methods are based on non-formal education and peer-to-peer learning. Thereby the programme facilitates the learning of crucial social and professional skills and encourages independent thinking and socio-political initiative.
              </Typography>
              <Typography variant="h8" color="textSecondary" align="left" paragraph>
                EYP Austria is part of an international network, organising events since 1987. The Berlin-based Schwarzkopf Foundation is the international umbrella organisation of the EYP.
              </Typography>
              <Typography variant="h8" color="textSecondary" align="left" paragraph>
                The European Youth Parliament is carried by students in a voluntary capacity and has no political mandate. All our activities are done within the EuropaClubs initiative (www.europaclubs.at).
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            EYP Sessions
          </Typography>
          <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
            An EYP session is an event where young people come together to meet and discuss current and exciting European topics. It is a unique experience for everyone and a chance to learn and have fun at the same time. There are various types of sessions, lasting between one and ten days.
          </Typography>
          <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
            To participate in some of our events, registration or application may be necessary. Please find detailed information on the respective event page.
          </Typography>
          <Grid container spacing={8} alignItems="center" mt={4}>
            <Grid item xs={12} md={6}>
              <Box textAlign="center">
                <Box
                  component="img"
                  src={teambuildingImage}
                  alt="Teambuilding"
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    
                  }}
                />
                <Typography variant="h4" gutterBottom sx={{ mt: 4, fontWeight: 'bold', color: '#333' }}>
                  Teambuilding
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  During Teambuilding, the participants, in their respective committees, get to know each other and learn to work together through a series of activities and games. It is an essential component that is crucial to allow for a fruitful discussion during Committee Work.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box textAlign="center">
                <Box
                  component="img"
                  src={committeeWorkImage}
                  alt="Committee Work"
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    
                  }}
                />
                <Typography variant="h4" gutterBottom sx={{ mt: 4, fontWeight: 'bold', color: '#333' }}>
                  Committee Work
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Throughout Committee Work, the Delegates analyse the topic of their committee, identify the problems surrounding it and discuss solutions to these issues. The output of Committee Work is a written resolution which, in terms of structure, is similar to the ones of the European Parliament.
                </Typography>
                {/* <Typography variant="h6" color="textSecondary" gutterBottom>
                  You can check out the resolutions of our previous sessions <Link href="#" color="primary">here</Link>.
                </Typography> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={8}>
        <Container maxWidth="xl">
          <Grid container spacing={8} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box textAlign="center">
                <Box
                  component="img"
                  src={generalAssemblyImage}
                  alt="General Assembly"
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    
                  }}
                />
                <Typography variant="h4" gutterBottom sx={{ mt: 4, fontWeight: 'bold', color: '#333' }}>
                  General Assembly
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  The last part of every session is a General Assembly (GA). In GA, the committees come together to present and debate the resolutions they wrote. In the end, the resolutions are put to a vote.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box textAlign="center">
                <Box
                  component="img"
                  src={culturalProgrammeImage}
                  alt="Cultural Programme"
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                   
                  }}
                />
                <Typography variant="h4" gutterBottom sx={{ mt: 4, fontWeight: 'bold', color: '#333' }}>
                  Cultural Programme
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Sessions are usually accompanied by a cultural programme, celebrating the cultural and culinary diversity of Europe. For example, Eurovillage lets you discover the foods of other countries.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            EYP in numbers
          </Typography>
          <Grid container spacing={4} justifyContent="center" mt={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 4, borderRadius: '8px', textAlign: 'center', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', } }}>
                <Typography variant="h4" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                  <CountUp end={25000} duration={3} separator="," />+
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  participants in total
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 4, borderRadius: '8px', textAlign: 'center', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', } }}>
                <Typography variant="h4" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                  <CountUp end={35} duration={3} />+
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  years of EYP history
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 4, borderRadius: '8px', textAlign: 'center', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', } }}>
                <Typography variant="h4" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                  <CountUp end={40} duration={3} />
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  countries involved
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 4, borderRadius: '8px', textAlign: 'center', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', } }}>
                <Typography variant="h4" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                  <CountUp end={400} duration={3} />+
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  events in Europe
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            EYP for...
          </Typography>
          <Grid container spacing={8} alignItems="flex-start" mt={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                Students
              </Typography>
              <Slider {...carouselSettings}>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Discuss your opinions:</strong> 
                    Have a say and discuss your opinions on current political issues whilst also hearing the standpoints of others to learn what they think.
                  </Typography>
                </Box>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Improve your soft skills:</strong> 
                    Raise your emotional intelligence by learning how to properly articulate your opinions and how to have fruitful discussions in which everyone has their say and feels comfortable.
                  </Typography>
                </Box>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Make international friends:</strong> 
                    The EYP is all about people. Make friends from all over Europe, learn about their culture and their opinions and meet them again at different sessions.
                  </Typography>
                </Box>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Discover Europe:</strong> 
                    Travel to sessions in countries all over Europe and get to know foreign cultures, customs and languages. Visit beautiful cities while having fun with people your age.
                  </Typography>
                </Box>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Educate yourself:</strong> 
                    Gain knowledge about the European Union, its institutions and its competences and apply this knowledge immediately in discussion about current pan-European issues.
                  </Typography>
                </Box>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Become part of a growing organisation:</strong> 
                    The EYP is an ever-growing network of motivated young Europeans who strive to make a change. Become part of our organisation, take on different roles and shape the future.
                  </Typography>
                </Box>
              </Slider>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                Teachers
              </Typography>
              <Slider {...carouselSettings}>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Language skills:</strong> As our working language is English, EYP is a great place for students to work on their language and communication skills in terms of vocabulary, rhetorical skills, and also expressiveness.
                  </Typography>
                </Box>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Politics:</strong> The topics discussed in EYP are all current and relevant pan-European issues that foster student’s understandings of the European Union and its workings in general.
                  </Typography>
                </Box>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Informal learning:</strong> EYP is education from young people for young people. By mutual learning in an informal context both hard skills (such as language and political knowledge) and soft skills are conveyed.
                  </Typography>
                </Box>
                <Box px={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <strong>Intercultural exchange:</strong> All our sessions are events with international participants. For example, Chairpersons and Delegates are oftentimes from foreign countries. This facilitates intercultural exchange and understanding.
                  </Typography>
                </Box>
              </Slider>
            </Grid>
          </Grid>
          <Grid container spacing={4} mt={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 4, borderRadius: '8px', fontStyle: 'italic', textAlign: 'center' }}>
                <Typography variant="body1">
                  "For me as a teacher of a higher technical school EYP is a great way to foster my highly motivated students to broaden their minds, improve their language skills and to give them the chance to raise their voices on topics that will shape their future."
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                  - Elke Stückler, Teacher at HTL Kaindorf for Mathematics and English
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 4, borderRadius: '8px', fontStyle: 'italic', textAlign: 'center' }}>
                <Typography variant="body1">
                  "I have had the great opportunity of observing the debates of young participants at a regional EYP Session. This experience has left me with some very positive conclusions: As a teacher, I consider the EYP an ideal instrument for enhancing pan-European political education that at the same time provides language training and the development of knowledge and skills.
                  <br />
                  As a counsellor for EU and Internationalisation at the local school authority in Styria, I advise all upper secondary schools in general and vocational education including vocational schools for apprentices to join the EYP programme and take part in their activities.
                  <br />
                  Personally, I have been highly impressed by the young participants’ subject-specific and rhetorical skills and by their dedication. Europe is alive."
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                  - Mag. Maria Pichlbauer, Counsellor for EU and Internationalisation at the local school authority in Styria
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            Team
          </Typography>
          <Grid container spacing={4} justifyContent="center" mt={4}>
            <Grid>
              <Box
                component="img"
                src={teamImage}
                alt="Team"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                }}
              />
            </Grid>
            <Grid>
              <Typography variant="h6" color="textSecondary" align="left" paragraph>
                The European Youth Parliament Austria is a national committee (NC) of the European Youth Parliament on the international level. EYP is an organisation from young people for young people. To that effect, our Board consists of seven elected EYP Austria members. Find more information about us in the contact section.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUsPage;
