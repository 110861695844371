import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, Button, Typography, Box, Avatar, IconButton, Grid, Paper } from '@mui/material';
import { getFirestore, collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {  Delete, Edit, ZoomIn, ZoomOut } from '@mui/icons-material';
import AvatarEditor from 'react-avatar-editor';

const CreateContactForm = () => {
  const [contacts, setContacts] = useState([]);
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [editContactId, setEditContactId] = useState(null);
  const [zoom, setZoom] = useState(1);
  const editorRef = useRef(null);

  useEffect(() => {
    const fetchContacts = async () => {
      const db = getFirestore();
      const contactsSnapshot = await getDocs(collection(db, 'contacts'));
      const contactsData = contactsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setContacts(contactsData);
    };

    fetchContacts();
  }, []);

  const handleImageUpload = async () => {
    if (!image) return imageUrl; // Return the existing URL if no new image is selected
    const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
    const storage = getStorage();
    const storageRef = ref(storage, `contact-images/${Date.now()}_${image.name}`);
    const blob = await (await fetch(canvas)).blob();
    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const handleAddContact = async (e) => {
    e.preventDefault();
    const db = getFirestore();
    try {
      const uploadedImageUrl = await handleImageUpload();
      if (editContactId) {
        const contactRef = doc(db, 'contacts', editContactId);
        await updateDoc(contactRef, {
          name,
          role,
          email,
          phone,
          imageUrl: uploadedImageUrl || imageUrl,
        });
        setEditContactId(null);
        alert('Contact updated successfully!');
      } else {
        await addDoc(collection(db, 'contacts'), {
          name,
          role,
          email,
          phone,
          imageUrl: uploadedImageUrl,
        });
        alert('Contact added successfully!');
      }
      setName('');
      setRole('');
      setEmail('');
      setPhone('');
      setImage(null);
      setImageUrl('');
      const contactsSnapshot = await getDocs(collection(db, 'contacts'));
      const contactsData = contactsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setContacts(contactsData);
    } catch (error) {
      console.error('Error adding contact: ', error);
      alert('Error adding contact. Please try again.');
    }
  };

  const handleDeleteContact = async (contactId) => {
    const db = getFirestore();
    try {
      await deleteDoc(doc(db, 'contacts', contactId));
      const updatedContacts = contacts.filter((contact) => contact.id !== contactId);
      setContacts(updatedContacts);
      alert('Contact deleted successfully!');
    } catch (error) {
      console.error('Error deleting contact: ', error);
      alert('Error deleting contact. Please try again.');
    }
  };

  const handleEditContact = (contact) => {
    setName(contact.name);
    setRole(contact.role);
    setEmail(contact.email);
    setPhone(contact.phone);
    setImageUrl(contact.imageUrl);
    setEditContactId(contact.id);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
    } else {
      alert('Invalid file type. Please select an image.');
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Create Contact
      </Typography>
      <form onSubmit={handleAddContact}>
        <Box mb={2}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Role"
            variant="outlined"
            fullWidth
            value={role}
            onChange={(e) => setRole(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Phone"
            variant="outlined"
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <Button variant="outlined" component="label">
            Upload Image
            <input type="file" accept="image/*" hidden onChange={handleImageChange} />
          </Button>
        </Box>
        {image && (
          <Box mb={2} textAlign="center">
            <AvatarEditor
              ref={editorRef}
              image={image}
              width={150}
              height={150}
              border={50}
              borderRadius={75}
              scale={zoom}
              rotate={0}
              style={{ width: '100%', height: 'auto' }}
            />
            <Box mt={2} display="flex" justifyContent="center">
              <IconButton onClick={() => setZoom(zoom + 0.1)}>
                <ZoomIn />
              </IconButton>
              <IconButton onClick={() => setZoom(zoom - 0.1)}>
                <ZoomOut />
              </IconButton>
            </Box>
          </Box>
        )}
        {imageUrl && !image && (
          <Box mb={2} textAlign="center">
            <Avatar src={imageUrl} sx={{ width: 120, height: 120, mx: 'auto' }} />
          </Box>
        )}
        <Button variant="contained" color="primary" type="submit">
          {editContactId ? 'Update Contact' : 'Add Contact'}
        </Button>
      </form>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Current Contacts
        </Typography>
        <Grid container spacing={2}>
          {contacts.map((contact) => (
            <Grid item xs={12} sm={6} md={4} key={contact.id}>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography variant="body1">{contact.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {contact.role}
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={() => handleEditContact(contact)} color="primary">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteContact(contact.id)} color="secondary">
                    <Delete />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CreateContactForm;
