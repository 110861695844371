import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Link, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConsent } from '../context/ConsentContext';

const CookieConsent = () => {
  const { handleConsentChange, consentDialogOpen, closeConsentDialog } = useConsent();
  const [showCustomize, setShowCustomize] = useState(false);
  const [consentState, setConsentState] = useState({ functional: true, analytics: false, thirdParty: false });
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    setConsentState({ functional: true, analytics: false, thirdParty: false });

    // Close the dialog if on the privacy policy page
    if (location.pathname === '/privacy') {
      closeConsentDialog();
    }
  }, [location.pathname, closeConsentDialog]);

  const handleChange = (event) => {
    setConsentState({ ...consentState, [event.target.name]: event.target.checked });
  };

  const handleSave = () => {
    handleConsentChange(consentState);
    closeConsentDialog();
  };

  const handleAcceptAll = () => {
    const allTrue = { functional: true, analytics: true, thirdParty: true };
    handleConsentChange(allTrue);
    setConsentState(allTrue);
    closeConsentDialog();
  };

  const handleDenyAll = () => {
    const allFalse = { functional: true, analytics: false, thirdParty: false }; // Functional must remain true
    handleConsentChange(allFalse);
    setConsentState(allFalse);
    closeConsentDialog();
  };

  return (
    <Dialog open={consentDialogOpen} onClose={closeConsentDialog} PaperProps={{ sx: { padding: theme.spacing(3), borderRadius: 3 } }}>
      <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Cookie Consent</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We use cookies to enhance your experience. By agreeing to one of the options below, you consent to the corresponding usage of cookies.
          You can view our <Link onClick={() => navigate('/privacy')} sx={{ cursor: 'pointer', textDecoration: 'underline' }}>Privacy Policy</Link>.
        </Typography>
        {showCustomize && (
          <Box>
            <FormControlLabel
              control={<Checkbox checked={consentState.functional} name="functional" disabled />}
              label="Functional Cookies (Required)"
              sx={{ display: 'block', mb: 1 }}
            />
            <Typography variant="body2" sx={{ ml: 4, mb: 2 }}>
              These cookies are necessary for the website to function and cannot be switched off in our systems.
            </Typography>

            <FormControlLabel
              control={<Checkbox checked={consentState.analytics} onChange={handleChange} name="analytics" />}
              label="Analytics Cookies"
              sx={{ display: 'block', mb: 1 }}
            />
            <Typography variant="body2" sx={{ ml: 4, mb: 2 }}>
              These cookies help us understand how our website is being used by visitors. All information collected is anonymous.
            </Typography>

            <FormControlLabel
              control={<Checkbox checked={consentState.thirdParty} onChange={handleChange} name="thirdParty" />}
              label="Third Party Cookies"
              sx={{ display: 'block', mb: 1 }}
            />
            <Typography variant="body2" sx={{ ml: 4, mb: 2 }}>
              These cookies may be set by third-party providers, like Instagram embeds, and help in offering you more personalized content.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {showCustomize ? (
          <>
            <Button onClick={handleSave} variant="contained" sx={{ mr: 2 }}>
              Save
            </Button>
            <Button onClick={() => setShowCustomize(false)} variant="outlined">
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleAcceptAll} variant="contained" sx={{ mr: 2 }}>
              Accept All
            </Button>
            <Button onClick={handleDenyAll} variant="outlined" sx={{ mr: 2 }}>
              Deny All
            </Button>
            <Button onClick={() => setShowCustomize(true)} variant="outlined">
              Customize
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsent;
