import React, { useEffect, useState } from 'react';
import {
  Container, Typography, Button, Grid, Card, CardContent, CardActions,
  IconButton, Tooltip, Tabs, Tab, Box
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { getAuth } from 'firebase/auth';
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../../components/ConfirmDialogue';
import usePermissionCheck from '../../../services/userPermissionCheck';

const Events = () => {
  const canManageEvents = usePermissionCheck('manage_events');
  const isAdmin = usePermissionCheck('admin');

  console.log('User can manage events:', canManageEvents);
  console.log('User is admin:', isAdmin);

  const [events, setEvents] = useState([]);
  const [backupEvents, setBackupEvents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [expandedEventId, setExpandedEventId] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const db = getFirestore();
      const eventsSnapshot = await getDocs(collection(db, 'events'));
      const eventsData = eventsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setEvents(eventsData);
      console.log('Fetched active events:', eventsData);
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const fetchBackupEvents = async () => {
      if (!isAdmin) return;
      const db = getFirestore();
      const backupSnapshot = await getDocs(collection(db, 'backup_events'));
      const backupData = backupSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setBackupEvents(backupData);
      console.log('Fetched backup events:', backupData);
    };
    fetchBackupEvents();
  }, [isAdmin]);

  const handleDelete = async (id) => {
    console.log('Attempting to delete event with ID:', id);
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('No user is signed in, cannot delete event.');
      return;
    }

    console.log('User UID:', user.uid);

    try {
      const eventDocRef = doc(db, 'events', id);
      const eventDoc = await getDoc(eventDocRef);

      if (!eventDoc.exists()) {
        console.error('Event does not exist or has already been deleted.');
        return;
      }

      const eventData = eventDoc.data();
      console.log('Event data to be backed up and deleted:', eventData);

      // Back up event
      try {
        const backupRef = doc(db, 'backup_events', id);
        await setDoc(backupRef, eventData);
        console.log('Event backed up successfully.');
      } catch (backupError) {
        console.error('Error backing up event:', backupError);
        return; // If backup fails, don't continue to delete
      }

      // Delete event
      try {
        await deleteDoc(eventDocRef);
        console.log('Event deleted successfully.');
        setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
      } catch (deleteError) {
        console.error('Error deleting event:', deleteError);
      }
    } catch (error) {
      console.error('Error retrieving event before deletion:', error);
    }
  };

  const handleOpenDialog = (id) => {
    setEventToDelete(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEventToDelete(null);
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    if (eventToDelete) {
      handleDelete(eventToDelete);
      handleCloseDialog();
    }
  };

  const toggleDescription = (id) => {
    setExpandedEventId(expandedEventId === id ? null : id);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRestore = async (id) => {
    console.log('Attempting to restore event with ID:', id);
    if (!isAdmin) {
      console.error('User is not admin. Cannot restore event.');
      return;
    }

    const db = getFirestore();
    const backupRef = doc(db, 'backup_events', id);
    const backupDoc = await getDoc(backupRef);

    if (!backupDoc.exists()) {
      console.error('No backup found for this event.');
      return;
    }

    const backupData = backupDoc.data();
    console.log('Backup data to restore:', backupData);

    try {
      await setDoc(doc(db, 'events', id), backupData);
      console.log('Event restored successfully.');
      // Optionally delete from backup
      // await deleteDoc(backupRef);

      // Update local state
      setEvents((prev) => [...prev, { id, ...backupData }]);
      setBackupEvents((prev) => prev.filter((evt) => evt.id !== id));
    } catch (error) {
      console.error('Error restoring event:', error);
    }
  };

  const renderEventsList = () => (
    <Grid container spacing={3}>
      {events.map((event) => (
        <Grid item xs={12} sm={12} md={6} key={event.id}>
          <Card sx={{ position: 'relative', minHeight: 200 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {event.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {new Date(event.startDate.toDate()).toLocaleDateString()} - {new Date(event.endDate.toDate()).toLocaleDateString()}
              </Typography>
              <Typography
                variant="body2"
                sx={{ mt: 2, mb: 1 }}
                dangerouslySetInnerHTML={{
                  __html: expandedEventId === event.id
                    ? event.description
                    : `${event.description.substring(0, 100)}...`,
                }}
              />
              {event.description.length > 100 && (
                <Button
                  size="small"
                  color="primary"
                  onClick={() => toggleDescription(event.id)}
                >
                  {expandedEventId === event.id ? 'Show Less' : 'Read More'}
                </Button>
              )}
            </CardContent>
            {canManageEvents && (
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Tooltip title="Edit">
                  <IconButton
                    color="primary"
                    onClick={() => navigate(`/edit-event/${event.id}`)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    color="secondary"
                    onClick={() => handleOpenDialog(event.id)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </CardActions>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const renderBackupEvents = () => (
    <Grid container spacing={3}>
      {backupEvents.map((evt) => (
        <Grid item xs={12} sm={12} md={4} key={evt.id}>
          <Card>
            <CardContent>
              <Typography variant="h6">{evt.title}</Typography>
              <Typography variant="body2">Starts: {new Date(evt.startDate.toDate()).toLocaleDateString()}</Typography>
              <Typography variant="body2">Ends: {new Date(evt.endDate.toDate()).toLocaleDateString()}</Typography>
              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: evt.description }} />
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" onClick={() => handleRestore(evt.id)}>
                Restore
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div>
      {canManageEvents || isAdmin ? (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Manage Events
          </Typography>
          {canManageEvents && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/create-event')}
              sx={{ mb: 2 }}
            >
              Create New Event
            </Button>
          )}
          {isAdmin ? (
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Active Events" />
                <Tab label="Backup & Restore" />
              </Tabs>
            </Box>
          ) : null}
          {isAdmin ? (
            <>
              {tabValue === 0 && renderEventsList()}
              {tabValue === 1 && renderBackupEvents()}
            </>
          ) : (
            // Non-admin but canManageEvents = partial scenario,
            // if only manage_events can do the tasks but not admin,
            // show just the active events without backups tab
            renderEventsList()
          )}
          <ConfirmDialog
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmDelete}
            title="Confirm Deletion"
            content="Are you sure you want to delete this event? This action cannot be undone."
          />
        </Container>
      ) : (
        // If the user does not have manage_events or admin,
        // just show events read-only without create/edit/delete.
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Events
          </Typography>
          {renderEventsList()}
        </Container>
      )}
    </div>
  );
};

export default Events;
