import { Add, Delete, Edit } from '@mui/icons-material';
import { ContentCopy } from '@mui/icons-material';
import {
  Button,
  Card,
  Box,
  CardActions,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Alert,
  Breadcrumbs,
  Link,
  Pagination,
  styled
} from '@mui/material';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, Timestamp, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../authContext';
import { firestore } from '../../../firebase';
import ErrorPage from '../../../components/ErrorPage';
import CallDialog from '../../../components/CallDialog';

const LoadingAnimation = '/loading_animation.svg';

function formatDate(date) {
  return date.toLocaleDateString(undefined, {
    year: 'numeric', month: 'short', day: 'numeric'
  });
}

// The text container with line-clamp in collapsed mode
const TextContainer = styled('div')(({ theme }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 4, 
  overflow: 'hidden',
  marginBottom: theme.spacing(1),
  flex: '0 1 auto'
}));

// Wrapper to hold text and the read more button
const DescriptionWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
}));

// Separate component to handle each call card
function CallCard({ call, isExpanded, onToggleExpand, onEdit, onDuplicate, onDelete }) {
  const [overflowing, setOverflowing] = useState(false);
  const textRef = React.useRef(null);

  const createdDate = call.createdAt ? call.createdAt.toDate() : null;
  const endDate = call.endDate.toDate();
  let endTime = '';
  
  if (endDate) {
    const hours = String(endDate.getHours()).padStart(2, '0');
    const minutes = String(endDate.getMinutes()).padStart(2, '0');
    endTime = `${hours}:${minutes}`;
  }

  useEffect(() => {
    if (textRef.current) {
      // Temporarily apply line clamp to measure
      textRef.current.style.WebkitLineClamp = !isExpanded ? '4' : 'unset';
      textRef.current.style.overflow = isExpanded ? 'auto' : 'hidden';

      const scrollHeight = textRef.current.scrollHeight;
      const clientHeight = textRef.current.clientHeight;
      setOverflowing(scrollHeight > clientHeight);
    }
  }, [call.description, isExpanded]);

  return (
    <Card sx={{ height: 350, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="h6" sx={{ mb: 1, color: 'primary.main' }}>
          {call.title}
        </Typography>
        {createdDate && (
          <>
            <Typography variant="body2" color="textSecondary">
              Created: {formatDate(createdDate)}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              Ends: {formatDate(endDate)}, {endTime}
            </Typography>
          </>
        )}
        {!createdDate && (
          <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            Ends: {formatDate(endDate)}
          </Typography>
        )}

        <DescriptionWrapper>
          <TextContainer
            ref={textRef}
            style={{
              WebkitLineClamp: !isExpanded ? '4' : 'unset',
              overflow: isExpanded ? 'auto' : 'hidden'
            }}
            dangerouslySetInnerHTML={{ __html: call.description }}
          />
          {overflowing && (
            <Button
              size="small"
              color="primary"
              onClick={onToggleExpand}
              sx={{ mt: 'auto' }}
            >
              {isExpanded ? 'Show Less' : 'Read More'}
            </Button>
          )}
        </DescriptionWrapper>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Tooltip title="Edit">
          <IconButton color="primary" onClick={onEdit}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Duplicate">
          <IconButton color="inherit" onClick={onDuplicate}>
            <ContentCopy />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="secondary" onClick={onDelete}>
            <Delete />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}

const AdminCalls = () => {
  const [calls, setCalls] = useState([]);
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [currentCallId, setCurrentCallId] = useState(null);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false);
  const [showOpenOnly, setShowOpenOnly] = useState(false);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expandedCallId, setExpandedCallId] = useState(null);

  // For dialog states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogInitialData, setDialogInitialData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  // For deletion confirmation
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCallId, setDeleteCallId] = useState(null);

  // Pagination
  const [page, setPage] = useState(1);
  const pageSize = 6;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const checkAdminRole = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userRoles = userDoc.data().roles || [];
          let hasAdminPermission = false;
          for (const roleId of userRoles) {
            const roleDoc = await getDoc(doc(firestore, 'roles', roleId));
            if (roleDoc.exists()) {
              const rolePermissions = roleDoc.data().permissions || [];
              if (rolePermissions.includes("manage_calls") || rolePermissions.includes("admin")) {
                hasAdminPermission = true;
                break;
              }
            }
          }
          setIsAdmin(hasAdminPermission);
        } else {
          setIsAdmin(false);
        }
      }
      setLoading(false);
    };

    const fetchCalls = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'calls'));
      let callsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Sort by creation date (newest first)
      callsData.sort((a, b) => {
        const aTime = a.createdAt ? a.createdAt.toMillis() : 0;
        const bTime = b.createdAt ? b.createdAt.toMillis() : 0;
        return bTime - aTime; 
      });
      setCalls(callsData);
      setFilteredCalls(callsData);
    };

    checkAdminRole();
    fetchCalls();
  }, [currentUser]);

  const applyFilters = () => {
    let filtered = calls;

    if (dateRange.start || dateRange.end) {
      const start = dateRange.start ? new Date(dateRange.start) : null;
      const end = dateRange.end ? new Date(dateRange.end) : null;
      filtered = filtered.filter(call => {
        const callEndDate = call.endDate.toDate();
        return (!start || callEndDate >= start) && (!end || callEndDate <= end);
      });
    }

    if (showOpenOnly) {
      const today = new Date();
      filtered = filtered.filter(call => call.endDate.toDate() >= today);
    }

    setFilteredCalls(filtered);
    setPage(1); // Reset to first page after filtering
  };

  useEffect(applyFilters, [dateRange, showOpenOnly, calls]);

  const handleOpenDateRangeDialog = () => setDateRangeDialogOpen(true);
  const handleCloseDateRangeDialog = () => setDateRangeDialogOpen(false);
  const handleDateRangeChange = (e) => setDateRange(prev => ({ ...prev, [e.target.name]: e.target.value }));
  const toggleShowOpenOnly = () => setShowOpenOnly(prev => !prev);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(firestore, 'calls', id));
      setCalls(calls.filter(call => call.id !== id));
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting call:', err);
      setError('Error deleting call. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleOpenDialogForCreate = () => {
    setDialogInitialData(null);
    setCurrentCallId(null);
    setIsEditing(false);
    setIsDuplicate(false);
    setDialogOpen(true);
  };

  const handleEdit = (call) => {
    setDialogInitialData(call);
    setCurrentCallId(call.id);
    setIsEditing(true);
    setIsDuplicate(false);
    setDialogOpen(true);
  };

  const handleDuplicate = (call) => {
    setDialogInitialData(call);
    setCurrentCallId(null);
    setIsEditing(true); // We are editing fields, but result is a new doc
    setIsDuplicate(true);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setIsDuplicate(false);
  };

  const handleSave = async (formData) => {
    try {
      let docUrl = formData.link;

      // If a new document file is uploaded
      if (formData.document) {
        const storage = getStorage();
        const storageRef = ref(storage, `call-documents/${formData.document.name}`);
        await uploadBytes(storageRef, formData.document);
        docUrl = await getDownloadURL(storageRef);
      }

      const callData = {
        title: formData.title,
        description: formData.description,
        endDate: Timestamp.fromDate(new Date(formData.endDate)),
        applyLink: docUrl || '',
      };

      if (currentCallId && !isDuplicate) {
        // Editing existing call
        await updateDoc(doc(firestore, 'calls', currentCallId), callData);
        setCalls(calls.map(call => (call.id === currentCallId ? { ...call, ...callData } : call)));
      } else {
        // Creating new call (from scratch or by duplicating)
        callData.createdAt = Timestamp.now(); // store creation time
        const newDoc = await addDoc(collection(firestore, 'calls'), callData);
        setCalls([...calls, { id: newDoc.id, ...callData }]);
      }

      setIsDuplicate(false);
      setDialogOpen(false);
    } catch (err) {
      console.error('Error creating/updating call:', err);
      setError('Error creating/updating call. Please try again.');
      setOpenSnackbar(true);
    }
  };

  if (loading) {
    return <img src={LoadingAnimation} alt="Loading" style={{ width: '150px' }} />;
  }

  if (!isAdmin) {
    return <ErrorPage message="You do not have permission to manage calls." />;
  }

  // Pagination slice
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedCalls = filteredCalls.slice(startIndex, endIndex);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Manage Calls
      </Typography>

      <Breadcrumbs separator="|" aria-label="filter breadcrumbs" sx={{ mb: 4, mt: 2 }}>
        <Link color="inherit" onClick={handleOpenDateRangeDialog} style={{ cursor: 'pointer' }}>
          Date Range
        </Link>
        <Link color="inherit" onClick={toggleShowOpenOnly} style={{ cursor: 'pointer' }}>
          {showOpenOnly ? "Show All" : "Open Only"}
        </Link>
      </Breadcrumbs>

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialogForCreate}
        sx={{ mb: 2 }}
        startIcon={<Add />}
      >
        Create New Call
      </Button>

      <Grid container spacing={3}>
        {paginatedCalls.map((call) => (
          <Grid item xs={12} sm={6} md={4} key={call.id}>
            <CallCard
              call={call}
              isExpanded={expandedCallId === call.id}
              onToggleExpand={() => setExpandedCallId(expandedCallId === call.id ? null : call.id)}
              onEdit={() => handleEdit(call)}
              onDuplicate={() => handleDuplicate(call)}
              onDelete={() => { setDeleteDialogOpen(true); setDeleteCallId(call.id); }}
            />
          </Grid>
        ))}
      </Grid>

      {filteredCalls.length > pageSize && (
        <Box mt={4} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(filteredCalls.length / pageSize)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this call?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={() => handleDelete(deleteCallId)} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateRangeDialogOpen} onClose={handleCloseDateRangeDialog}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date"
            name="start"
            value={dateRange.start}
            onChange={handleDateRangeChange}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            name="end"
            value={dateRange.end}
            onChange={handleDateRangeChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDateRangeDialog}>Cancel</Button>
          <Button onClick={() => { applyFilters(); handleCloseDateRangeDialog(); }} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      <CallDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSave}
        initialData={dialogInitialData}
        isEditing={isEditing}
      />

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminCalls;
