import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { firestore } from '../firebase';

const usePermissionCheck = (requiredPermission) => {
  const [canAccess, setCanAccess] = useState(false);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const checkAccessPermissions = async () => {
      try {
        if (currentUser) {
          // Step 1: Get the user's roles from their user document
          const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userRoles = userDoc.data().roles || [];

            // Step 2: Check each role for the required permission
            let hasPermission = false;
            for (const roleId of userRoles) {
              const roleDoc = await getDoc(doc(firestore, 'roles', roleId));
              if (roleDoc.exists()) {
                const rolePermissions = roleDoc.data().permissions || [];
                
                // Check if the role has the required permission or is an admin role
                if (rolePermissions.includes(requiredPermission) || rolePermissions.includes('admin')) {
                  hasPermission = true;
                  break;
                }
              }
            }

            setCanAccess(hasPermission);
          } else {
            setCanAccess(false);
          }
        } else {
          setCanAccess(false);
        }
      } catch (error) {
        console.error(`Error checking ${requiredPermission} permissions:`, error);
        setCanAccess(false);
      }
    };

    checkAccessPermissions();
  }, [currentUser, requiredPermission]);

  return canAccess;
};

export default usePermissionCheck;
