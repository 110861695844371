import React, { useRef, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Avatar,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { ZoomIn, ZoomOut, Save, Delete } from '@mui/icons-material';
import AvatarEditor from 'react-avatar-editor';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const ContactFormDialog = ({
  open,
  editMode,
  initialContact,
  onClose,
  onSave,
}) => {
  const [contactName, setContactName] = useState('');
  const [role, setRole] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactImage, setContactImage] = useState(null);
  const [contactImageUrl, setContactImageUrl] = useState('');
  const [isBoardMember, setIsBoardMember] = useState(false);
  const [isAdvisoryBoard, setIsAdvisoryBoard] = useState(false);
  const [isSafePerson, setIsSafePerson] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const editorRef = useRef(null);

  useEffect(() => {
    // Reset fields whenever initialContact changes (including going to null for a new contact)
    if (initialContact) {
      setContactName(initialContact.name || '');
      setRole(initialContact.role || '');
      setContactEmail(initialContact.email || '');
      setContactPhone(initialContact.phone || '');
      setContactImageUrl(initialContact.imageUrl || '');
      setContactImage(null); // no new image chosen yet
      setIsBoardMember(initialContact.isBoardMember || false);
      setIsAdvisoryBoard(initialContact.isAdvisoryBoard || false);
      setIsSafePerson(initialContact.isSafePerson || false);
    } else {
      // New contact - clear fields
      setContactName('');
      setRole('');
      setContactEmail('');
      setContactPhone('');
      setContactImage(null);
      setContactImageUrl('');
      setIsBoardMember(false);
      setIsAdvisoryBoard(false);
      setIsSafePerson(false);
    }
    setZoom(1);
  }, [initialContact]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setContactImage(file);
    } else {
      setOpenSnackbar(true);
      setErrorMessage('Invalid file type. Please select an image.');
    }
  };

  const handleImageUpload = async () => {
    // If no new image selected, return the existing imageUrl
    if (!contactImage) return contactImageUrl;

    const storage = getStorage();
    const uniqueName = `${Date.now()}-${contactImage.name}`;
    const storageRef = ref(storage, `contact-images/${uniqueName}`);

    const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
    const blob = await (await fetch(canvas)).blob();

    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);
    setContactImageUrl(url);
    return url;
  };

  const handleDeleteImage = () => {
    setContactImage(null);
    setContactImageUrl('');
  };

  const handleChangeImage = () => {
    // Clears current image and allows user to upload a new one
    setContactImage(null);
    setContactImageUrl('');
  };

  const validateForm = () => {
    if (!contactName || !role || !contactEmail || !contactPhone) {
      setOpenSnackbar(true);
      setErrorMessage('All fields are required');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const imageUrl = await handleImageUpload();
      onSave({
        name: contactName,
        role,
        email: contactEmail,
        phone: contactPhone,
        imageUrl,
        isBoardMember,
        isAdvisoryBoard,
        isSafePerson,
      });
    } catch (err) {
      console.error('Error creating/updating contact:', err);
      setOpenSnackbar(true);
      setErrorMessage('Error creating/updating contact. Please try again.');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{editMode ? 'Edit/Duplicate Contact' : 'Create Contact'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Contact Name"
            variant="outlined"
            fullWidth
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Role"
            variant="outlined"
            fullWidth
            value={role}
            onChange={(e) => setRole(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Contact Email"
            variant="outlined"
            fullWidth
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Contact Phone"
            variant="outlined"
            fullWidth
            value={contactPhone}
            onChange={(e) => setContactPhone(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isBoardMember}
                  onChange={(e) => setIsBoardMember(e.target.checked)}
                  name="boardMember"
                />
              }
              label="Board Member"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAdvisoryBoard}
                  onChange={(e) => setIsAdvisoryBoard(e.target.checked)}
                  name="advisoryBoard"
                />
              }
              label="Advisory Board"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSafePerson}
                  onChange={(e) => setIsSafePerson(e.target.checked)}
                  name="safePerson"
                />
              }
              label="Safe Person"
            />
          </Box>

          <Box mb={2}>
            <Typography variant="h6">Contact Image</Typography>
            {contactImageUrl && !contactImage && (
              <Box mt={2} display="flex" flexDirection="column" alignItems="center">
                <Avatar src={contactImageUrl} sx={{ width: 56, height: 56 }} />
                <Button variant="outlined" sx={{ mt: 1 }} onClick={handleChangeImage}>
                  Change Image
                </Button>
              </Box>
            )}
            {!contactImageUrl && !contactImage && (
              <>
                <Button variant="outlined" component="label">
                  Upload Image
                  <input type="file" accept="image/*" hidden onChange={handleFileChange} />
                </Button>
              </>
            )}
            {contactImage && (
              <Box mt={2} textAlign="center">
                <AvatarEditor
                  ref={editorRef}
                  image={contactImage}
                  width={150}
                  height={150}
                  border={50}
                  borderRadius={75}
                  scale={zoom}
                  rotate={0}
                  style={{ width: '100%', height: 'auto' }}
                />
                <Box mt={2} display="flex" justifyContent="center">
                  <IconButton onClick={() => setZoom(zoom + 0.1)}>
                    <ZoomIn />
                  </IconButton>
                  <IconButton onClick={() => setZoom(zoom - 0.1)}>
                    <ZoomOut />
                  </IconButton>
                  <IconButton onClick={handleImageUpload}>
                    <Save />
                  </IconButton>
                  <IconButton onClick={handleDeleteImage}>
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {editMode ? 'Save Changes' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ContactFormDialog;
